import React from "react";
import { BooleanInput, required, SelectInput, SimpleForm, TextInput, useEditContext } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";
import { USER_STATUS_LIST } from "../../constant/display";

const UserEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    {type === 'edit' &&
                        <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="User ID"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="uuid"
                                           source="uuid"
                                           label="User UUID"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       label="Username"
                                       type="text"
                                       validate={[required()]}
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="email"
                                       source="email"
                                       label="E-mail"
                                       type="email"
                                       validate={[required()]}
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <BooleanInput name="changePassword"
                                          source="changePassword"
                                          label="Change Password?"
                                          readOnly={type === "create"}
                                          defaultValue={type === "create"}
                                          fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="password"
                                       source="password"
                                       label="Password"
                                       type="password"
                                       validate={[required()]}
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="confirmPassword"
                                       source="confirmPassword"
                                       label="Confirm Password"
                                       type="password"
                                       validate={[required()]}
                                       fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         source="status"
                                         choices={USER_STATUS_LIST}
                                         label="Status"
                                         defaultValue="A"
                                         validate={[required()]}
                                         fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <BooleanInput name="isSuperAdmin"
                                          source="isSuperAdmin"
                                          label="Super Admin"
                                          fullWidth
                            />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </SimpleForm>
    )
}

export default UserEditForm;
