import {
    DateField,
    RecordContextProvider,
    TextField,
    useGetManyReference,
    useList,
    useListContext,
    useTranslate
} from "react-admin";
import { Avatar, Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import CustomShowButton from "../../../component/CustomShowButton";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";

const VoucherRemarkView = () => {

    const translate = useTranslate();
    const { data, isLoading } = useListContext();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Grid xs={12} md={3}>
                            <Card elevation={1}>
                                <CardHeader
                                    sx={{ pt: 1, pb: 0 }}
                                    avatar={
                                        <Avatar sx={{ bgcolor: "#455858" }} aria-label="recipe">
                                            {record.createdBy[0]}
                                        </Avatar>
                                    }
                                    title={record.createdBy}
                                    subheader={
                                        <DateField source="createdDate" showTime />
                                    }
                                    action={
                                        <Stack component="div" spacing={1} direction="row">
                                            <CustomEditButton IconButtonProps={{ disabled: record.type === "S" }}
                                                              customPath={`/voucher/${record.recordId}/remark/${record.recordId}/edit`} />
                                            <CustomShowButton
                                                customPath={`/voucher/${record.recordId}/remark/${record.recordId}/show`} />
                                            <CustomDeleteIconButton
                                                IconButtonProps={{ disabled: record.type === "S" }} />
                                        </Stack>
                                    }
                                />
                                <CardContent sx={{
                                    pt: 1,
                                    pb: 0,
                                    "&:last-child": {
                                        pb: 1,
                                    }
                                }}>
                                    <TextField source="remark" sortable={false} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </RecordContextProvider>
                ))}
            </Grid>
        </Box>
    );
};

export default VoucherRemarkView;
