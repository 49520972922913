import React, { MouseEvent, useState } from "react";
import {
    AppBar,
    AppBarProps,
    Layout,
    LayoutProps,
    useUserMenu,
    UserMenu,
    Logout,
    LoadingIndicator, ToggleThemeButton,
} from 'react-admin';

import { MyMenu } from './MyMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router";
import { parseJwt } from "./auth/AuthUtils";
import TranslateIcon from '@mui/icons-material/Translate';
import { useLocales, useLocaleState } from "ra-core";

export const MyLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => (
    <Layout {...props} menu={MyMenu} appBar={MyAppBar} sx={{
        "& .MuiDrawer-root": {
            marginTop: "0 !important",
        },
        "& .RaLayout-appFrame": {
            marginTop: "48px",
        },
        "& .RaLayout-content": {
            px: {
                xs: 0,
                md: 1,
            },
        },
    }} />
);

export const MyAppBar = (props: JSX.IntrinsicAttributes & AppBarProps) => (
    <AppBar container="div"
            userMenu={<MyUserMenu />}
            toolbar={<><LoadingIndicator /><ToggleThemeButton /></>} />
);


const ConfigurationMenu = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { onClose } = useUserMenu();
    const jwtPayload: any = parseJwt();
    if (jwtPayload.projects.length <= 1) return null;

    const onClick = () => {
        onClose();
        navigate('/projectSelect');
    }

    return (
        <MenuItem onClick={onClick} {...props}>
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Switch project</ListItemText>
        </MenuItem>
    );
});


const ChangePasswordMenu = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { onClose } = useUserMenu();

    const onClick = () => {
        onClose();
        navigate("/password/change");
    }

    return (
        <MenuItem onClick={onClick} {...props}>
            <ListItemIcon>
                <KeyIcon />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
        </MenuItem>
    );
});

const LocalesMenu = () => {
    const locales = useLocales();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const languages = useLocales();
    const [ locale, setLocale ] = useLocaleState();

    if (locales.length === 0) {
        return null;
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const changeLocale = (locale: string) => (): void => {
        setLocale(locale);
        setAnchorEl(null);
    };

    const handleLanguageClick = (event: MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };


    return (
        <>
            <MenuItem onClick={handleLanguageClick}>
                <ListItemIcon>
                    <TranslateIcon />
                </ListItemIcon>
                <ListItemText>Language</ListItemText>
            </MenuItem>
            <Menu id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
            >
                {languages.map(language => (
                    <MenuItem
                        key={language.locale}
                        onClick={changeLocale(language.locale)}
                        selected={language.locale === locale}
                    >
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export const MyUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu />
        <ChangePasswordMenu />
        <LocalesMenu />
        <Logout />
    </UserMenu>
);