import React, { useMemo } from "react";
import ReactApexChart, { Props } from "react-apexcharts";
import { Paper } from "@mui/material";
import { renderToString } from "react-dom/server";

const VoucherConsumptionRetailerChart: React.FC<VoucherConsumptionRetailerChartProps> = ({ type, details }) => {

    const state: Props = useMemo<Props>(() => {

        const consumptionRetailerMapping = details.reduce((prev: ConsumptionRetailerUsageMapping, current, index) => {
            const retailerName = current.retailerName ?? "Not Specified";
            if (!prev[retailerName])
                prev[retailerName] = {
                    retailerId: current.retailerId ? current.retailerId : -1,
                    retailerName: retailerName,
                    usedAmount: 0.0,
                    savedAmount: 0.0,
                };

            prev[retailerName].usedAmount += current.usedAmount;
            prev[retailerName].savedAmount += current.usedAmount * current.discountRate;

            return prev;
        }, {});

        const { data, labels } = Object.keys(consumptionRetailerMapping).reduce((prev: { data: number[], labels: string[] }, key, index) => {
            const { data, labels } = prev;
            if( type === "saved") {
                data.push(consumptionRetailerMapping[key].savedAmount);
            } else {
                data.push(consumptionRetailerMapping[key].usedAmount);
            }
            labels.push(consumptionRetailerMapping[key].retailerName);

            return prev;
        }, {
            data: [],
            labels: [],
        });

        return {
                series: [...data],
                options: {
                    labels: [...labels],
                    dataLabels: {
                        formatter(_: number, opts: any): any {
                            return [
                                `${opts.w.globals.labels[opts.seriesIndex]}`,
                                `£${opts.w.globals.series[opts.seriesIndex].toFixed(2)}`
                            ];
                        },
                    },
                    chart: {
                        type: "donut",
                    },
                    stroke: {
                        show: false,
                    },
                    plotOptions: {
                        pie: {
                            expandOnClick: false,
                            donut: {
                                labels: {
                                    show: true,
                                    value: {
                                        formatter(w: string): string {
                                            console.log("W: ", w);
                                            return `£${(+w).toFixed(2)}`;
                                        }
                                    },
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        formatter(w): string {
                                            return `£ ${w.config.series.reduce((prev: number, curr: number) => prev + curr, 0).toFixed(2)}`
                                        }
                                    },
                                },
                            },
                        },
                    },
                    theme: {
                        mode: "dark",
                    },
                    legend: {
                        position: "bottom",
                    },
                    title: {
                        text: type === "normal" ? "Normal Amount" : "Saved Amount",
                        align: "center",
                    },
                    tooltip: {
                        enabled: true,
                        custom: (opts) => {
                            const retailerName = opts.w.globals.labels[opts.seriesIndex];
                            return renderToString(
                                <Paper>
                                    Retailer name: {retailerName} <br/>
                                    Used Amount: £{opts.w.globals.series[opts.seriesIndex].toFixed(2)} <br />
                                    Saved Amount: £{consumptionRetailerMapping[retailerName].savedAmount.toFixed(2)}
                                </Paper>
                            );
                        },
                    },
                },
            };
    }, [ details, type ]);

    return (
        <Paper sx={{
            m: 1,
            p: 3,
            maxHeight: {
                xs: "500px",
                md: "600px",
            },
            maxWidth: {
                xs: "100%",
                md: "600px",
            },
            overflow: "hidden",
        }}>
            <ReactApexChart options={state.options} series={state.series} type="donut" />
        </Paper>
    );

};

export default VoucherConsumptionRetailerChart;
