import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Alert, Box, Button, Paper } from "@mui/material";
import moment from "moment";
import { useOctopusTariffV4 } from "./data/useOctopusData";
import Grid from "@mui/material/Unstable_Grid2";
import OctopusTariffTableV4 from "./component/OctopusTariffTableV4";
import OctopusTariffChartV4 from "./component/OctopusTariffChartV4";
import { OctopusAccount, OctopusAgreement, OctopusProperty, TariffSearchPeriod } from "./types/types";

export type OctopusV4TariffProps = {
    account: OctopusAccount;
    selectedProperty: OctopusProperty;
};

const OctopusV4Tariff: React.FC<OctopusV4TariffProps> = ({ account, selectedProperty }) => {

    const [ period, setPeriod ] = useState<TariffSearchPeriod>({
        start: moment().startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
    });

    const periodAgreements = useMemo(() => {
        const finalAgreements: OctopusAgreement[] = [];

        selectedProperty.agreements.forEach((agreement: any) => {
            if (moment(period.start).isBetween(agreement.validFrom, agreement.validTo, "day", "[]")) {
                finalAgreements.push({
                    ...agreement,
                    validFrom: period.start.toISOString(),
                    validTo: moment(agreement.validTo).isAfter(period.end) ? period.end.toISOString() : agreement.validTo,
                });
            } else if (moment(period.end).isBetween(agreement.validFrom, agreement.validTo, "day", "[]")) {
                finalAgreements.push({
                    ...agreement,
                    validFrom: moment(agreement.validFrom).isBefore(period.start) ? period.start.toISOString() : agreement.validFrom,
                    validTo: period.end.toISOString(),
                });
            } else if (moment(agreement.validFrom).isBetween(period.start, period.end, "day", "[]") && moment(agreement.validTo).isBetween(period.start, period.end, "day", "[]")) {
                finalAgreements.push({ ...agreement });
            }
        });

        return finalAgreements;
    }, [ selectedProperty.agreements, period.start, period.end ]);

    const {
        isError,
        data: tariffs,
        isLoading,
        isFetching,
        refetch,
    } = useOctopusTariffV4(periodAgreements);

    const changePreviousDay = () => {
        setPeriod((prev) => ({
            start: moment(prev.start).subtract(1, "day").toDate(),
            end: moment(prev.end).subtract(1, "day").toDate(),
        }));
    };

    const changeNextDay = () => {
        setPeriod((prev) => ({
            start: moment(prev.start).add(1, "day").toDate(),
            end: moment(prev.end).add(1, "day").toDate(),
        }));
    };

    const isNextDateButtonDisabled = useCallback(() => {
        return (
            moment(period.start).isSame(moment().add(1, "day"), "day")
        );
    }, [period.start]);

    const sortedTariffs = useMemo(() => {
        if (!tariffs || tariffs.length === 0) return [];
        return tariffs.sort((a, b) => moment(a.validFrom).isBefore(b.validFrom) ? -1 : 1);
    }, [tariffs])

    return (
        <>
            <Box>
                <Paper
                    component="div"
                    square={true}
                    elevation={0}
                    sx={{
                        position: "sticky",
                        top: "48px",
                        zIndex: 200,
                        backgroundColor: "rgb(231, 235, 240)",
                    }}
                >
                    <Grid container>
                        <Grid xs={2} md={1}>
                            <Button color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={changePreviousDay}
                                    variant="contained"
                                    disableElevation
                                    sx={{ borderRadius: 0 }}
                            >
                                {"<<"}
                            </Button>
                        </Grid>
                        <Grid xs={8} md={3} sx={{
                            textAlign: "center",
                            color: "#000",
                        }}>
                            {moment(period.start).format("YYYY-MM-DD (dddd)")}
                        </Grid>
                        <Grid xs={2} md={1}>
                            <Button color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={changeNextDay}
                                    variant="contained"
                                    disableElevation
                                    sx={{ borderRadius: 0 }}
                                    disabled={isNextDateButtonDisabled()}
                            >
                                {">>"}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                {(! isLoading && !isFetching && (! tariffs || tariffs.length === 0)) &&
                    <Alert severity="warning" color="warning">
                        沒有紀錄 / 明天費用資料將於每天四點至八點釋出。 <Button onClick={() => refetch()}>Refresh</Button>
                    </Alert>
                }

                {isError &&
                    <Alert severity="error" color="error">
                        Error during fetching data, please try later.
                    </Alert>
                }

                {tariffs && tariffs.length > 0 &&
                    <Fragment>
                        <OctopusTariffTableV4 results={sortedTariffs} />
                        <OctopusTariffChartV4 values={sortedTariffs} />
                    </Fragment>
                }

            </Box>
        </>
    )
};

export default OctopusV4Tariff;
