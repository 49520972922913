import React, { Fragment, useContext, useEffect, useState } from "react";
import { OctopusV4Context } from "./context/OctopusV4Context";
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Divider } from "@mui/material";
import {
    Form,
    RecordContextProvider,
    required,
    ResourceContextProvider,
    TextInput,
    useRedirect
} from "react-admin";
import { useUpdateOctopusAccount } from "./data/useOctopusData";

const OctopusV4TokenUpdate: React.FC = () => {

    const { account: { isLoading, data, refetch: accountRefetch } } = useContext(OctopusV4Context);


    const TestForm = () => {

        const redirect = useRedirect();
        const [ result, setResult ] = useState<string>("");


        useEffect(() => {
            console.log("rendering");
        }, []);

        const [ update, { data, isLoading, isError } ] = useUpdateOctopusAccount({
            onSuccess: (data, variables, context) => {
                setResult("Successfully refreshed");
                accountRefetch();
                redirect("/energy/octopus/v4/index");
            },
            onError: () => {
                setResult("Error! Please try again.");
            },
        });

        const onSubmitHandler = (value: any) => {
            console.log(value);
            setResult("");

            update(value.apiKey, value.accountId);
        };

        return (
            <ResourceContextProvider value="dummy">
                <RecordContextProvider value={{ apiKey: undefined, accountId: undefined }}>
                    <Form
                        mode="onBlur"
                        reValidateMode="onBlur"
                        onSubmit={onSubmitHandler}
                    >
                        <Card sx={{ mt: 3 }}>
                            <CardHeader title="Octopus Account Configuration" />
                            <Divider />
                            <CardContent>

                                <Alert severity="info" color="info" sx={{ mt: 2 }}>
                                    We need API token to access data by Octopus API, which is provided by Octopus itself. <br />
                                    The API token is an unique key for each account.
                                </Alert>

                                { result.length > 0 && (
                                    <Alert severity="info" color="info" sx={{ mt: 2 }}>
                                        { result }
                                    </Alert>
                                )}

                                <Box sx={{ pt: 2 }}>
                                    <TextInput
                                        name="apiKey"
                                        source="apiKey"
                                        validate={[ required() ]}
                                        readOnly={isLoading}
                                        helperText={
                                            <Box>
                                                You can generate an API key from the [<a href="https://octopus.energy/dashboard/developer/">developer dashboard</a>]
                                            </Box>
                                        }
                                        fullWidth
                                    />
                                </Box>

                                <Box sx={{ pt: 2 }}>
                                    <TextInput
                                        name="accountId"
                                        label="Account ID"
                                        source="accountId"
                                        validate={[ required() ]}
                                        readOnly={isLoading}
                                        helperText={
                                            <Box>
                                                You can check account ID from [<a href="https://octopus.energy/dashboard">online dashboard</a>]
                                            </Box>
                                        }
                                        fullWidth
                                    />
                                </Box>
                            </CardContent>
                            <Divider />
                            <CardContent>
                                <Alert severity="warning">
                                    This website will just use the information you pvovided to gather the information:
                                    <ul>
                                        <li>Tariff product</li>
                                        <li>Tariff product code</li>
                                        <li>Effective start date</li>
                                        <li>Effective end date</li>
                                        <li>Consumptions</li>
                                    </ul>
                                    The API key will not be able to modify any information from your account, and we never do that.
                                </Alert>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    Submit
                                </Button>
                            </CardActions>
                        </Card>
                    </Form>
                </RecordContextProvider>
            </ResourceContextProvider>
        );
    };


    return (
        <Fragment>
            {! isLoading && data &&
                <Alert severity="warning" color="warning" sx={{ mt: 3 }}>
                    Setting detected on system, if you have tariff plan change,
                    you may also resubmit this form to update it.
                </Alert>
            }
            <TestForm />
        </Fragment>
    );
};

export default OctopusV4TokenUpdate;
