import React, { useEffect, useRef } from "react";
import { BrowserMultiFormatReader, Result } from "@zxing/library";

const NewScanner: React.FC<{
    resultHandler: (result: Result) => void;
    codeReader: React.MutableRefObject<BrowserMultiFormatReader>;
}> = ({ resultHandler, codeReader }) => {

    const loadingQuery = useRef(false);

    // const hints = new Map();
    // const formats = [ BarcodeFormat.QR_CODE, BarcodeFormat.EAN_8, BarcodeFormat.EAN_13 ];
    // hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    // hints.set(DecodeHintType., formats);

    // const codeReader = useRef(new BrowserMultiFormatReader());

    useEffect(() => {
        if (loadingQuery.current) return;
        console.log('ZXing code reader initialized');

        loadingQuery.current = true;

        // Zxing cannot auto get permission???
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'environment'
            }
        })
            .then((stream) => {
                stream.getTracks().forEach((track) => {
                    codeReader.current.decodeOnceFromVideoDevice(
                        track.getSettings().deviceId ?? "", "video"
                    )
                        .then((result) => {
                            if (result) {
                                resultHandler(result);
                                codeReader.current.reset();
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                    track.stop();
                });
            })
            .catch((error) => {
                console.log(error);
            });

    }, [codeReader, resultHandler]);

    return (
        <main>
            <section className="container" id="demo-content">

                <div>
                    <video id="video" width="300" height="200" />
                </div>

            </section>
        </main>
    );
}

export default NewScanner;
