import React from "react";
import {
    BooleanField,
    Datagrid,
    FilterForm,
    FunctionField,
    List,
    TextField,
    TextInput,
    useListContext,
    useTranslate
} from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box, Button, Card, CardContent, CardHeader, Divider, InputAdornment, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CreateIconButton from "../../component/CreateIconButton";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomCloneButton from "../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../component/CustomDeleteIconButtonV2";

const RetailerList: React.FC = () => {

    const translate = useTranslate();

    const retailerListBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            isCurrent: true,
        },
    ];

    const listFilters = [
        <TextInput label="common.table.search"
                   source="q"
                   name="q"
                   alwaysOn
                   InputProps={{
                       endAdornment: (
                           <InputAdornment position="end">
                               <SearchIcon color="disabled" />
                           </InputAdornment>
                       ),
                   }} />,
    ];

    const defaultFilters = {}

    const ListFilter = () => {
        const { setFilters } = useListContext();

        return (
            <Stack direction="row" justifyContent="space-between">
                <FilterForm filters={listFilters} />
                <Button onClick={() => setFilters(defaultFilters, {})}>
                    {translate("common.table.filter.reset")}
                </Button>
            </Stack>
        );
    }

    return (
        <List
            actions={false}
            exporter={false}
            sort={{ field: 'recordId', order: 'ASC' }}
            perPage={25}
            component="div"
            title="Retailer Management"
            filterDefaultValues={defaultFilters}
            empty={false}
        >
            <Box>
                <CustomBreadcrumbs title={translate('Retailer')} items={retailerListBreadcrumbItems} />
                <Card>
                    <CardHeader
                        title="Retailers"
                        action={<CreateIconButton />}
                    />
                    <Divider />
                    <CardContent sx={{ padding: "2px 16px" }}>
                        <ListFilter />
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField label="common.table.actions"
                                           render={() => (
                                               <Stack component="div" spacing={1} direction="row">
                                                   <CustomShowButton />
                                                   <CustomEditButton />
                                                   <CustomCloneButton
                                                       omitProps={[
                                                           'id', 'recordId', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate',
                                                       ]}
                                                   />
                                                   <CustomDeleteIconButtonV2 IconButtonProps={{
                                                       sx: {
                                                           "svg": {
                                                               fontSize: "16px",
                                                           }
                                                       }
                                                   }} />
                                               </Stack>
                                           )} />
                            <TextField source="name" />
                            <BooleanField source="isVoucherRetailer" label="Voucher Retailer" />
                            <TextField source="recordId" label="#" />
                        </Datagrid>
                    </CardContent>
                </Card>
            </Box>
        </List>
    )
}

export default RetailerList;
