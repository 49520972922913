import React, { Fragment, useEffect } from "react";
import { Alert, AlertTitle, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Authenticated, Title, useGetList } from "react-admin";
import { getProjectUuid, getSelectedProject, parseJwt } from "./auth/AuthUtils";
import { useNavigate } from "react-router";
import CustomButton from "./component/CustomButton";
import CustomIconButton from "./component/CustomIconButton";
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import config from "./config.json";

export const Dashboard = () => {

    const DashboardContent = () => {
        const navigate = useNavigate();
        const jwtPayload: any = parseJwt();
        const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];
        // ensure run one time only

        useEffect(() => {

            if (! getProjectUuid()) {
                const jwtPayload: any = parseJwt();
                const projects = jwtPayload.projects ? Object.keys(jwtPayload.projects) : [];

                console.log(projects)
                if (projects.length === 0) {
                    console.log('No Project Assigned!');
                } else if (projects.length === 1) {
                    console.log('only one project! Auto assign that project to localStorage');
                    localStorage.setItem('project', projects[0]);
                } else {
                    if (projects.length > 1) {
                        console.log('More than one project was found!');
                        navigate('/projectSelect');
                    }
                }
            }
        }, [ navigate ]);

        if (projects.length === 0)
            return (
                <Fragment>
                    You don't have any projects assigned. Please contact Administrator.
                </Fragment>
            )

        return (
            <Fragment>
                <Title title="Dashboard" />
                <Typography variant="h6" p={2}>Welcome to HomeCloud</Typography>
            </Fragment>
        );
    };

    const AnnouncementPortal = () => {

        const { data: announcements, total, isLoading, error, refetch } = useGetList(
            "announcement",
            {
                pagination: { page: 1, perPage: 25 },
                sort: { field: "recordId", order: "DESC" },
                filter: {},
                meta: {
                    projectUuid: getProjectUuid(),
                }
            },
            {
                enabled: getProjectUuid() !== null
            }
        );

        if (isLoading || !announcements     || announcements.length === 0) return;

        return (
            <Fragment>
                <Typography variant="h6" m={2}>Announcements</Typography>
                {announcements.map((announcement: Announcement) => (
                    <Alert severity="warning" key={announcement.recordId}>
                        <AlertTitle>By {announcement.createdBy}</AlertTitle>
                        {announcement.message}
                    </Alert>
                ))}
            </Fragment>
        )
    };

    return (
        <Authenticated requireAuth>
            <DashboardContent />
            <AnnouncementPortal />
        </Authenticated>
    );
};
