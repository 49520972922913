import { DateField, RecordContextProvider, RichTextField, TextField, useListContext, useTranslate } from "react-admin";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    Stack,
    Theme,
    Typography,
    useMediaQuery
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";
import { useNavigate, useParams } from "react-router-dom";
import { WARNING } from "../../../constant/Color";
import Grid from "@mui/material/Unstable_Grid2";
import NatureIcon from "@mui/icons-material/Nature";

const EquipmentNoteGridView = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams()["*"];

    const { tab, selectedId } = useMemo(() => {
        let tab, selectedId;
        if (!params)
            return { tab, selectedId };
        const split = params.split("/");
        return {
            tab: split[0],
            selectedId: split.length > 1 ? split[1]: undefined,
        };
    }, [params]);

    const translate = useTranslate();
    const { data, isLoading } = useListContext<EquipmentNote>();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [ selectedNote, setSelectedNote ] = useState<EquipmentNote | undefined>(undefined);

    useEffect(() => {
        if (isLoading || data.length === 0 ) {
        } else {
            const targetIndex = data.findIndex(note => String(note.id) === selectedId);
            if (targetIndex === -1) {
                navigate(`/equipment/${id}/show/${tab}`, { replace: true });
                return;
            }
            setSelectedNote(data[targetIndex]);
        }
    }, [isLoading, data, selectedId, navigate, id, tab]);

    if (isLoading) {
        return null;
    }

    if (data.length === 0) {
        return (
            <Paper>
                No note for this equipment.
            </Paper>
        )
    }

    const selectNote = (record: EquipmentNote) => {
        // setSelectedNote(record);
        navigate(`/equipment/${id}/show/${tab}/${record.id}`, { replace: true });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexFlow: isXSmall ? "column" : "row",
            }}
        >
            <Box
                sx={{
                    px: 1,
                    wordBreak: "break-word",
                    maxWidth: {
                        xs: "90vw",
                        md: "200px",
                    },
                    overflowX: {
                        xs: "scroll",
                        md: "auto",
                    },
                    minHeight: {
                        xs: undefined,
                        md: "150px",
                    },
                    maxHeight: {
                        xs: undefined,
                        md: "900px",
                    },
                    overflowY: {
                        xs: "auto",
                        md: "scroll",
                    },
                }}
            >
                <Stack
                    sx={{ mt: 1 }}
                    spacing={2} direction={isXSmall ? "row" : "column"}>
                    {data.map(record => (
                        <RecordContextProvider key={record.id} value={record}>
                            <Card
                                elevation={3}
                                sx={{
                                    backgroundColor: String(record.recordId) === String(selectedId) ? WARNING.normal : undefined,
                            }}
                                onClick={() => selectNote(record)}
                            >
                                <CardHeader
                                    title={record.title}
                                    subheader={record.createdBy}
                                />
                            </Card>
                        </RecordContextProvider>
                    ))}
                </Stack>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    pl: {
                        sm: 0,
                        md: 1,
                    },
                    pt: {
                        sm: 1,
                        md: 0,
                    },
                    maxHeight: {
                        xs: undefined,
                        md: "900px",
                    },
                }}
            >

                {! selectedNote && (
                    <Paper sx={{ height: "100%", mt: 1 }}>Select note to view</Paper>
                )}
                {!! selectedNote && (
                    <RecordContextProvider key={selectedNote.id} value={selectedNote}>
                        <Card sx={{ height: "100%", mt: 1 }}>
                            <CardHeader
                                title={selectedNote.title}
                                action={
                                    <Stack component="div" spacing={1} direction="row">
                                        <CustomEditButton
                                            customPath={`/equipment/${id}/note/${selectedNote.recordId}/edit`} />
                                        <CustomDeleteIconButton
                                            onSuccessFunction={() => {
                                                // refetch();
                                                // queryClient.invalidateQueries({
                                                //     predicate: (query) => {
                                                //         return query.queryKey.length === 3 &&
                                                //             (query.queryKey[0] === "voucherConsumption" || query.queryKey[0] === "voucherRemark") &&
                                                //             query.queryKey[1] === "getManyReference" &&
                                                //             (query.queryKey[2] as any)?.target === "voucherId" &&
                                                //             (query.queryKey[2] as any)?.id === record.recordId
                                                //     }
                                                // });
                                                // queryClient.invalidateQueries({
                                                //     predicate: (query) => {
                                                //         return query.queryKey.length === 3 &&
                                                //             (query.queryKey[0] === "voucher") &&
                                                //             query.queryKey[1] === "getList"
                                                //     }
                                                // });
                                            }}
                                        />
                                    </Stack>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <Grid container>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Created by</Typography>
                                            <TextField source="createdBy" /> @ <DateField source="createdDate"
                                                                                          showTime />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} display="flex">
                                        <NatureIcon fontSize="small" />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">Last Updated</Typography>
                                            <TextField source="updatedBy" /> @ <DateField source="updatedDate"
                                                                                          showTime />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardContent sx={{ maxHeight: "600px",overflowY: "scroll" }}>
                                <RichTextField source="content" />
                            </CardContent>
                        </Card>
                    </RecordContextProvider>
                )}
            </Box>
        </Box>
    );
};

export default EquipmentNoteGridView;
