import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import { CustomEdit } from "../../component/custom/ra/CustomEdit";
import { Box } from "@mui/material";
import BrandEditForm from "./BrandEditForm";

const BrandEdit = () => {

    const { id } = useParams();
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Brands',
            resource: 'brand',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'brand',
            type: 'show',
            id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <CustomEdit actions={false}
                    component="div"
                    mutationMode="pessimistic"
                    title="Brand Management"
                    resource="brand"
                    id={id}
                    redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Edit" items={breadcrumbItems} />
                <BrandEditForm type="edit" />
            </Box>
        </CustomEdit>
    );

};

export default BrandEdit;
