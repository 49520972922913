import React, { Fragment } from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import {
    Datagrid,
    DateField,
    FunctionField,
    NumberField,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    TextField,
    useShowContext,
} from "react-admin";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Fade,
    Grid,
    Stack,
} from "@mui/material";
import CustomEditButton from "../../component/CustomEditButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import VoucherStatus from "./constant/VoucherStatus";
import CustomShowButton from "../../component/CustomShowButton";
import GroupedButton from "../../component/GroupedButton";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import CreateIconButton from "../../component/CreateIconButton";
import CopyMe from "../../component/common/CopyMe";
import VoucherBarcodeDialog from "./VoucherBarcodeDialog";
import moment from "moment";
import { useQueryClient } from "react-query";
import VoucherRemarkView from "./component/VoucherRemarkView";

const VoucherShowLayout = () => {

    const queryClient = useQueryClient();
    const { record, isLoading, refetch } = useShowContext<Voucher>();

    if (isLoading)
        return <CircularProgress />;

    if (! record)
        return <>Record Not Found</>;

    return (
        <Fade in={true} timeout={500}>
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardHeader title={
                        <Fragment>
                            {record.name} &nbsp;
                            {record.barcodeText && (
                                <VoucherBarcodeDialog barcodeText={record.barcodeText} barcodeType={record.barcodeType}
                                                      small />
                            )}
                        </Fragment>
                    } action={<GroupedButton />} />
                    <Divider />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4}>
                                Record ID: {record.recordId}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Name: {record.name}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Purchase Date: {moment(record.purchaseDate).format("yyyy-MM-DD")}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                Issuer: {record.issuer}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Amount: £{record.amount?.toFixed(2)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Used Amount: £{record.usedAmount.toFixed(2)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Balance: £{(record.amount - record.usedAmount).toFixed(2)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Actual Cost: £{(record.actualCost).toFixed(2)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Saved:
                                £{(record.amount - record.actualCost).toFixed(2)} | {(((record.amount - record.actualCost) / record.amount) * 100).toFixed(2)}%
                                off
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Status: <SelectField choices={VoucherStatus} source="status" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                URL: {record.url && (
                                <Button href={record.url}
                                        color="primary"
                                        variant="outlined"
                                        target="_blank"
                                        size="small"
                                >
                                    <LaunchOutlinedIcon />
                                    Open
                                </Button>
                            )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Number: <CopyMe text={record.number} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Pin: <CopyMe text={record.pin} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                Effective Date: {record.effectiveStartDate} - {record.effectiveEndDate}
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid container>
                            <Grid item xs={12}>
                                <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                            </Grid>
                            <Grid item xs={12}>
                                <>Last Updated
                                    By {record.updatedBy} @ {new Date(record.updatedDate).toLocaleString()}</>
                            </Grid>
                        </Grid>

                        {record.transferableVoucherId && (
                            <Alert severity="info">
                                This voucher is transferred by Transferable Voucher:
                                <ReferenceField source="transferableVoucherId"
                                                reference="transferable/voucher"
                                                link="show"
                                >
                                    &nbsp; &nbsp; <TextField source="name" />
                                </ReferenceField>
                            </Alert>
                        )}
                    </CardContent>
                </Card>

                <Card sx={{ mt: 5 }} variant="outlined" color="error">
                    <CardHeader title="Consumptions" action={<CreateIconButton
                        customPath={`/voucher/${record.recordId}/consumption/create`} />} />
                    <Divider />
                    <CardContent>
                        <ReferenceManyField label="Vouchers Consumption" reference="voucherConsumption"
                                            target="voucherId"
                                            pagination={<Pagination />} perPage={5}
                                            sort={{ field: 'purchaseDate', order: 'ASC' }}>
                            <Datagrid bulkActionButtons={false}>
                                <FunctionField label="Actions" render={(voucherConsumptionRecord: any) => (
                                    <Stack component="div" spacing={1} direction="row">
                                        <CustomEditButton
                                            customPath={`/voucher/${record.recordId}/consumption/${voucherConsumptionRecord.recordId}/edit`} />
                                        <CustomShowButton
                                            customPath={`/voucher/${record.recordId}/consumption/${voucherConsumptionRecord.recordId}/show`} />
                                        <CustomDeleteIconButton
                                            onSuccessFunction={() => {
                                                refetch();
                                                queryClient.invalidateQueries({
                                                    predicate: (query) => {
                                                        return query.queryKey.length === 3 &&
                                                            (query.queryKey[0] === "voucherConsumption" || query.queryKey[0] === "voucherRemark") &&
                                                            query.queryKey[1] === "getManyReference" &&
                                                            (query.queryKey[2] as any)?.target === "voucherId" &&
                                                            (query.queryKey[2] as any)?.id === record.recordId
                                                    }
                                                });
                                                queryClient.invalidateQueries({
                                                    predicate: (query) => {
                                                        return query.queryKey.length === 3 &&
                                                            (query.queryKey[0] === "voucher") &&
                                                            query.queryKey[1] === "getList"
                                                    }
                                                });
                                            }}
                                        />
                                    </Stack>
                                )} />
                                <DateField source="purchaseDate" />
                                <NumberField source="amount"
                                             options={{ style: 'currency', currency: 'GBP' }}
                                             textAlign="left" />
                                <TextField label="Created By" source="createdBy" sortable={false} />
                            </Datagrid>
                        </ReferenceManyField>
                    </CardContent>
                </Card>

                <Card sx={{ mt: 5 }}>
                    <CardHeader title="Remarks"
                                action={<CreateIconButton
                                    customPath={`/voucher/${record.recordId}/remark/create`} />} />
                    <Divider />
                    <CardContent>
                        <ReferenceManyField label="Vouchers Remarks" reference="voucherRemark"
                                            target="voucherId"
                                            pagination={<Pagination rowsPerPageOptions={[8, 16, 24, 32, 64]} />}
                                            perPage={8}
                                            sort={{ field: 'createdDate', order: 'DESC' }}
                        >
                            <VoucherRemarkView />
                            {/*<Datagrid bulkActionButtons={false}>*/}
                            {/*    <FunctionField label="Actions" render={(remarkRecord: any) => (*/}
                            {/*        <Stack component="div" spacing={1} direction="row">*/}
                            {/*            <CustomEditButton IconButtonProps={{ disabled: remarkRecord.type === "S" }}*/}
                            {/*                              customPath={`/voucher/${record.recordId}/remark/${remarkRecord.recordId}/edit`} />*/}
                            {/*            <CustomShowButton*/}
                            {/*                customPath={`/voucher/${record.recordId}/remark/${remarkRecord.recordId}/show`} />*/}
                            {/*            <CustomDeleteIconButton*/}
                            {/*                IconButtonProps={{ disabled: remarkRecord.type === "S" }} />*/}
                            {/*        </Stack>*/}
                            {/*    )} />*/}
                            {/*    <TextField source="remark" sortable={false} />*/}
                            {/*    <DateField source="createdDate" sortable={true} showTime={true} />*/}
                            {/*    <TextField source="createdBy" sortable={false} />*/}
                            {/*    <TextField source="type" sortable={false} />*/}
                            {/*</Datagrid>*/}
                        </ReferenceManyField>
                    </CardContent>
                </Card>
            </Box>
        </Fade>
    );
}

const VoucherShow = (props: any) => {

    const voucherShowBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Index',
            path: "/voucher",
        },
        {
            label: 'List',
            path: "/voucher/list",
        },
        {
            label: 'Record',
            isCurrent: true,
        },
    ];

    return (
        <Show actions={false}
              component="div"
              title="Voucher Management"
              {...props}
        >
            <Box component="div">
                <CustomBreadcrumbs title="View" items={voucherShowBreadcrumbItems} />
                <VoucherShowLayout />
            </Box>
        </Show>
    );

};

export default VoucherShow;
