import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "react-query";
import getFolderDetails from "./getFolderDetails";

const useGetFolderDetails = (folderId?: string) => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";

    return useQuery(
        ['getFolderDetails', folderId],
        () => getFolderDetails(projectUuid, userUuid, folderId),
        {
            enabled: projectUuid.length > 0 && userUuid.length > 0,
        }
    );
};

export default useGetFolderDetails;
