import React, { Fragment, useState } from "react";
import {
    Datagrid,
    FunctionField,
    Pagination,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    Show,
    TextField as RaTextField,
    useNotify,
    useShowContext
} from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import Loading from "../../component/common/Loading";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import CustomEditButtonV2 from "../../component/CustomEditButtonV2";
import { COMMON_STATUS } from "../../constant/CommonStatus";
import { DUE_DATE_TYPE } from "../../constant/DueDateType";
import CustomEditButton from "../../component/CustomEditButton";
import CustomShowButton from "../../component/CustomShowButton";
import CustomDeleteIconButton from "../../component/CustomDeleteIconButton";
import CreateIconButton from "../../component/CreateIconButton";
import { uploadImage } from "../../hooks/data/dataHooks";
import { useParams } from "react-router-dom";

const ProductShow: React.FC = () => {

    const { id } = useParams();
    const [ uploadDialogOpen, setUploadDialogOpen ] = useState(false);

    const uploadDialogOpenHandler = () => {
        setUploadDialogOpen(true);
    }

    const uploadDialogCloseHandler = () => {
        setUploadDialogOpen(false);
    }

    const ImageUploadDialog = () => {

        const noifty = useNotify();
        const { refetch } = useShowContext();
        const [file, setFile] = useState<File>();
        const [isLoading, setLoading] = useState(false);

        const uploadDialogSubmitHandler = () => {
            if (file && id) {
                setLoading(true);
                uploadImage(id, file).then(
                    () => {
                        refetch();
                        uploadDialogCloseHandler();
                        setLoading(false);
                    }
                ).catch((error) => {
                    noifty(error.response.data.message);
                })
            }
        }

        return (
            <Dialog open={uploadDialogOpen} onClose={uploadDialogCloseHandler}>
                <DialogTitle>Upload Image</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please select file</DialogContentText>
                    <TextField autoFocus
                               id="file"
                               name="file"
                               type="file"
                               inputProps={{
                                   onChange: (e) => {
                                       const files = (e.target as any).files;
                                       if (files) {
                                           setFile(files[0]);
                                       } else {
                                           setFile(undefined);
                                       }
                                   }
                               }}
                               fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="error"
                            variant="contained"
                            onClick={uploadDialogCloseHandler}
                    >
                        Cancel
                    </Button>
                    <Button color="primary"
                            variant="contained"
                            onClick={uploadDialogSubmitHandler}
                            disabled={isLoading}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const ShowLayout: React.FC = () => {

        const { isLoading, record } = useShowContext();
        if (isLoading) {
            return <Loading />;
        }

        return (
            <Fragment>

                <Card sx={{ width: "100%" }}>
                    <CardHeader title={`Product #${record.recordId}`} action={<CustomEditButtonV2 />} />
                    <Divider />
                    <CardContent>
                        <Stack direction={{ xs: "column", md: "row", }} spacing={5}>

                            <Box>
                                {record.imagePath && (
                                    <Box
                                        component="img"
                                        src={record.imagePath}
                                        sx={{ width: "300px", maxWidth: "75vw" }}
                                    />
                                )}
                                {! record.imagePath && (
                                    <Paper sx={{
                                        width: {
                                            md: "300px",
                                        },
                                        maxWidth: {
                                            md: "75vw",
                                        },
                                        height: {
                                            md: "300px",
                                        },
                                        py: {
                                            xs: "15px",
                                            md: "125px",
                                        }, textAlign: "center",
                                    }}>
                                        <Typography>No image.</Typography>
                                        <Typography>
                                            <Button variant="contained" onClick={uploadDialogOpenHandler}>Upload</Button>
                                        </Typography>
                                    </Paper>
                                )}
                            </Box>

                            <Box>
                                <Typography>Record ID: {record.recordId}</Typography>
                                <Typography>Name: {record.name}</Typography>
                                <Typography>Description: {record.description}</Typography>

                                <Typography>Status: <SelectField source="status" choices={COMMON_STATUS} /></Typography>

                                <Typography>Minimum Stock Amount: {record.minStockAmount}</Typography>

                                <Typography>Default Due Date Type: <SelectField source="defaultDueDateType"
                                                                                choices={DUE_DATE_TYPE} /></Typography>
                                <Typography>
                                    Default Shop: <ReferenceField source="defaultShopId" reference="shop" link="show">
                                    <RaTextField source="name" />
                                </ReferenceField>
                                </Typography>

                                <Typography>
                                    Default Stock Location: <ReferenceField source="defaultStockLocationId"
                                                                            reference="stockLocation" link="show"
                                                                            emptyText="N/A">
                                    <RaTextField source="name" />
                                </ReferenceField>
                                </Typography>

                                <Typography>
                                    Quantity Unit: <ReferenceField source="quantityUnitId" reference="quantityUnit"
                                                                   link="show">
                                    <RaTextField source="nameSingular" />
                                </ReferenceField>
                                </Typography>

                                <Typography>
                                    Product Group: <ReferenceField source="productGroupId" reference="productGroup"
                                                                   link="show">
                                    <RaTextField source="name" />
                                </ReferenceField>
                                </Typography>

                                <Typography>
                                    Brand: <ReferenceField source="brandId" reference="brand" link="show">
                                    <RaTextField source="name" />
                                </ReferenceField>
                                </Typography>
                            </Box>

                        </Stack>

                    </CardContent>
                </Card>

                <Card sx={{ mt: 5 }}>
                    <CardHeader title="Barcode" action={<CreateIconButton
                        customPath={`/product/${record.recordId}/barcode/create`} />} />
                    <Divider />
                    <CardContent>
                        <ReferenceManyField reference="product/barcode" target="productId"
                                            pagination={<Pagination />} perPage={5}
                                            sort={{ field: "createdDate", order: "DESC" }}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <FunctionField label="Actions" render={(remarkRecord: any) => (
                                    <Stack component="div" spacing={1} direction="row">
                                        <CustomEditButton IconButtonProps={{ disabled: remarkRecord.type === "S" }}
                                                          customPath={`/product/${record.recordId}/barcode/${remarkRecord.recordId}/edit`} />
                                        <CustomShowButton
                                            customPath={`/voucher/${record.recordId}/remark/${remarkRecord.recordId}/show`} />
                                        <CustomDeleteIconButton
                                            IconButtonProps={{ disabled: remarkRecord.type === "S" }} />
                                    </Stack>
                                )} />
                                <RaTextField source="code" sortable={false} />
                                <RaTextField source="format" sortable={false} />
                                <RaTextField source="note" sortable={false} />
                                <RaTextField source="createdDate" sortable={true} showTime={true} />
                                <RaTextField source="createdBy" sortable={false} />
                            </Datagrid>
                        </ReferenceManyField>
                    </CardContent>
                </Card>

                <ImageUploadDialog />

            </Fragment>
        );
    }

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Products',
            isCurrent: false,
            resource: 'product',
            type: 'list',
        },
        {
            label: 'Record',
            isCurrent: true,
        },
    ];

    return (
        <Show actions={false}
              component="div"
        >
            <CustomBreadcrumbs title="Product View" items={[ ...breadcrumbItems ]} />
            <ShowLayout />
        </Show>
    )
}

export default ProductShow;
