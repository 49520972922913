import React, { Fragment } from "react";
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Chip, Dialog, DialogContent, DialogTitle,
    IconButton,
    Link,
    Stack,
    Typography
} from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DescriptionIcon from '@mui/icons-material/Description';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Grid from "@mui/material/Unstable_Grid2";
import ReportIcon from "@mui/icons-material/Report";
import { Link as RouterLink, useParams } from "react-router-dom";
import DocumentBreadcrumbs from "./component/DocumentBreadcrumbs";
import useGetFolderDetails from "./data/useGetFolderDetails";

const DocumentExplorer = () => {

    const { folderId } = useParams();

    const { data: currentFolder, isLoading } = useGetFolderDetails(folderId);

    if (isLoading || !currentFolder)
        return "Loading...";

    return (
        <Fragment>
            <Alert color="info" sx={{ my: 1 }} icon={<ReportIcon />}>Experiment function, mobile view is not supported</Alert>

            <DocumentBreadcrumbs />

            <Card sx={{ my: 1 }}>
                <CardHeader title="Folders" />
                <CardContent>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {currentFolder.children?.map((folder, index) => (
                            <Grid xs={2} sm={4} md={3} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={<FolderIcon sx={{ color: "yellow" }} />}
                                        title={folder.name}
                                        subheader={folder.description}
                                        action={<IconButton><MoreHorizIcon /></IconButton>}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>

            <Card sx={{ my: 1 }}>
                <CardHeader title="Files" />
                <CardContent>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {currentFolder.files?.map((file, index) => (
                            <Grid xs={2} sm={4} md={3} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={<DescriptionIcon sx={{ color: "text.secondary" }} />}
                                        title={
                                            <Link
                                                component={RouterLink}
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                to={"/document/preview"}
                                            >
                                                {file.filename}
                                            </Link>
                                        }
                                        action={<IconButton><MoreHorizIcon /></IconButton>}
                                    />
                                    <CardContent>
                                        <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                                            <Chip color="primary" size="small" label="Chip Filled" />
                                            <Chip color="secondary" size="small" label="Chi346346p Filled" />
                                            <Chip color="warning" size="small" label="Chip F333illed" />
                                            <Chip color="error" size="small" label="Chip Fill3463ed" />
                                            <Chip color="default" size="small" label="Chip F33illed" />
                                        </Stack>
                                        <Typography variant="body2" color="text.secondary">Uploaded: 2023-08-09 23:30:30 by Peter</Typography>
                                        <Typography variant="body2" color="text.secondary">Modified: 2023-08-09 23:30:30 by Peter</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>

            <Dialog open={false} fullScreen>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    <embed
                        width="100%"
                        height="100%"
                        src="https://5hrldw.sharepoint.com/sites/HomeCloudData/_layouts/15/embed.aspx?uniqueId=724e6586-a484-4018-b8b4-3608a9fc1494&access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiI2dW9oay5jb20iLCJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvNWhybGR3LnNoYXJlcG9pbnQuY29tQDE0NDFmYjczLTkwNzMtNGViOC05ODY4LTA0ZDRlNzFlNDMxNyIsImNpZCI6IkVnZzVSU0VlY3NxTUYxV3B1WTQ4MHc9PSIsImVuZHBvaW50dXJsIjoicXlXLzVCQXpQM2I1Wm9RMlhXbG1IdFhFWFFoWW05V1ZDNk1WckZPSHpuZz0iLCJlbmRwb2ludHVybExlbmd0aCI6IjExOCIsImV4cCI6IjE3MTQwNTU4ODMiLCJpcGFkZHIiOiI0MC4xMjYuNDEuMTYwIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYW1laWQiOiI5ZThmMWQ2MC1iMGJlLTQ0YWYtYmIxYy04YWUzNWRkYmI2NzFAMTQ0MWZiNzMtOTA3My00ZWI4LTk4NjgtMDRkNGU3MWU0MzE3IiwibmJmIjoiMTcxNDA1NTU4MyIsInJvbGVzIjoiYWxsc2l0ZXMucmVhZCBhbGxmaWxlcy53cml0ZSIsInNpdGVpZCI6IllqZGpOVGhoWlRRdFltSmlNeTAwWVRreUxXRm1OVFl0WmpaaFlqazJNVGcxTXpBdyIsInR0IjoiMSIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.M-ZIcwiy9VG7KdOyK86DjWrYoOEjee1Kh9oxBHVl79Q" />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DocumentExplorer;
