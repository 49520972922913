import React, { useEffect, useMemo } from "react";
import { Paper } from "@mui/material";
import ReactApexChart, { Props } from "react-apexcharts";
import moment from "moment";

const VoucherConsumptionYearChart: React.FC<VoucherConsumptionYearChartProps> = ({ details }) => {

    const state: Props = useMemo<Props>(() => {

        const { purchaseDateStart, purchaseDateEnd } = {
            purchaseDateStart: moment().startOf("month").subtract(12, "months"),
            purchaseDateEnd: moment().endOf("month"),
        };

        const periodDiff = purchaseDateEnd.diff(purchaseDateStart, "month") + 1;
        const period: any = {};
        const dateStringArray = Array.from({ length: periodDiff }, (v, i) => {
            const date = purchaseDateStart.clone().add(i, "month");
            if (moment().startOf("month").isSameOrAfter(date)) {
                const dateString = purchaseDateStart.clone().add(i, "month").format("YYYY-MM-DD");
                period[dateString] = {
                    usedAmount: 0,
                    savedAmount: 0,
                };
                return dateString;
            }
            return "";
        }).filter(value => value !== "");

        const consumptionMapping = details.reduce((prev: any, current) => {
            const dateString = moment(current.purchasedDate).startOf("month").format("YYYY-MM-DD");
            if (! prev[dateString]) {
                prev[dateString] = {
                    usedAmount: 0,
                    savedAmount: 0,
                }
            }
            const { usedAmount, savedAmount } = prev[dateString];
            prev[dateString] = {
                usedAmount: usedAmount + current.usedAmount,
                savedAmount: savedAmount + current.usedAmount * current.discountRate,
            }

            return prev;
        }, {...period});

        return {
            series: [
                {
                    name: "Used Amount",
                    type: "line",
                    data: dateStringArray.map(key => ({
                        x: key,
                        y: consumptionMapping[key].usedAmount,
                    }))
                },
                {
                    name: "Saved Amount",
                    type: "line",
                    data: dateStringArray.map(key => ({
                        x: key,
                        y: consumptionMapping[key].savedAmount,
                    }))
                },
            ],
            options: {
                chart: {
                    zoom: {
                        enabled: false,
                    },
                },
                theme: {
                    mode: "dark",
                },
                yaxis: [
                    {
                        labels: {
                            show: true,
                            formatter(val: number, opts?: any): string | string[] {
                                return val.toFixed(2);
                            }
                        }
                    },
                    {
                        opposite: true,
                        labels: {
                            show: true,
                            formatter(val: number, opts?: any): string | string[] {
                                return val.toFixed(2);
                            }
                        }
                    },
                ]
            },
        }
    }, [ details ]);

    return (
        <Paper sx={{
            m: 1,
            p: 3,
            maxHeight: {
                xs: "500px",
                md: "600px",
            },
            maxWidth: {
                xs: "100%",
                md: "600px",
            },
            overflow: "hidden",
        }}>
            <ReactApexChart options={state.options} series={state.series} type="line" />
        </Paper>
    );
};

export default VoucherConsumptionYearChart;
