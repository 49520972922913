import React from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { CustomEdit } from "../../../component/custom/ra/CustomEdit";
import { Box } from "@mui/material";
import ProjectEditForm from "./ProjectEditForm";

const ProjectEdit = () => {

    const { projectId } = useParams();
    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: "Projects",
            resource: "project",
            type: "list",
        },
        {
            label: "Projects",
            resource: "project",
            type: "show",
            id: projectId,
        },
        {
            label: "Edit",
            isCurrent: true,
        },
    ];

    return (
        <CustomEdit actions={false}
                    component="div"
                    mutationMode="pessimistic"
                    title="Project Management"
                    resource="project"
                    id={projectId}
                    redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Edit" items={breadcrumbItems} />
                <ProjectEditForm type="edit" />
            </Box>
        </CustomEdit>
    );
}

export default ProjectEdit;
