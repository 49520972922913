import React from "react";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import { Create, useRedirect } from "react-admin";
import VoucherConsumptionEditForm from "./VoucherConsumptionEditForm";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { trackEvent } from "../../../support/googleAnalytics";

const VoucherConsumptionCreate: React.FC = () => {

    const queryClient = useQueryClient();
    const { voucherId } = useParams();
    const redirect = useRedirect();

    const voucherConsumptionCreateBreadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Consumption',
            isCurrent: true,
        },
    ];


    return (
        <Create actions={false}
                component="div"
                title="Voucher Consumption"
                resource="voucherConsumption"
                mutationOptions={{
                    onSuccess: (data: any) => {
                        trackEvent("voucher", "add-consumption", `voucher ID: ${data.voucherId}`);
                        trackEvent("voucher-consumption", "add", `voucher consumption ID: ${data.recordId}, voucher ID: ${data.voucherId}`);
                        queryClient.invalidateQueries({
                            predicate: (query) => {
                                return query.queryKey.length === 3 && ((
                                        query.queryKey[0] === "voucherRemark" &&
                                        query.queryKey[1] === "getManyReference" &&
                                        (query.queryKey[2] as any)?.target === "voucherId" &&
                                        (query.queryKey[2] as any)?.id === data.voucherId ) ||
                                    ((query.queryKey[0] === "voucher") &&
                                        query.queryKey[1] === "getOne" &&
                                        (query.queryKey[2] as any)?.id === `${data.voucherId}`) ||
                                    ((query.queryKey[0] === "voucher") &&
                                        query.queryKey[1] === "getList" &&
                                        (query.queryKey[2] as any)?.id === `${data.voucherId}`))
                            }
                        });
                        redirect(`/voucher/${voucherId}/show`);
                    },
                }}
                redirect={`/voucher/${voucherId}/show`}
        >
            <CustomBreadcrumbs title="Create Voucher Consumption" items={voucherConsumptionCreateBreadcrumbItems} />
            <VoucherConsumptionEditForm type="create" />
        </Create>
    );
}

export default VoucherConsumptionCreate;
