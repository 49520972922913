import { DateField, RecordContextProvider, TextField, useListContext, useTranslate } from "react-admin";
import { Avatar, Box, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomEditButton from "../../../component/CustomEditButton";
import CustomShowButton from "../../../component/CustomShowButton";
import CustomDeleteIconButton from "../../../component/CustomDeleteIconButton";
import React from "react";
import CustomCloneButton from "../../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../../component/CustomDeleteIconButtonV2";

const EquipmentListGrid = () => {

    const translate = useTranslate();
    const { data, isLoading } = useListContext();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {data.map(record => (
                    <RecordContextProvider key={record.id} value={record}>
                        <Grid xs={12} md={3}>
                            <Card elevation={1} sx={{ minHeight: 100 }}>
                                <CardHeader
                                    title={
                                        <Typography variant="body2">
                                            {record.name}<br />{!! record.nameZhHk ? record.nameZhHk : ``}
                                        </Typography>
                                    }
                                    action={
                                        <Stack component="div" spacing={1} direction="row">
                                            <CustomShowButton />
                                            <CustomEditButton />
                                            <CustomCloneButton
                                                omitProps={[
                                                    'id', 'recordId', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate',
                                                ]}
                                            />
                                            <CustomDeleteIconButtonV2 IconButtonProps={{
                                                sx: {
                                                    "svg": {
                                                        fontSize: "16px",
                                                    }
                                                }
                                            }} />
                                        </Stack>
                                    }
                                />
                            </Card>
                        </Grid>
                    </RecordContextProvider>
                ))}
            </Grid>
        </Box>
    );
};

export default EquipmentListGrid;
