import React from "react";
import { Create, useNotify, useRedirect } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../../component/CustomBreadcrumbs";
import VoucherRemarkEditForm from "./VoucherRemarkEditForm";
import { useParams } from "react-router-dom";
import { trackEvent } from "../../../support/googleAnalytics";

const VoucherRemarkCreate = () => {

    const { voucherId } = useParams();
    const redirect = useRedirect();
    const notify = useNotify();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Vouchers',
            resource: 'voucher',
            type: 'list',
        },
        {
            label: 'Record',
            resource: 'voucher',
            type: 'show',
            id: voucherId,
        },
        {
            label: 'Remark',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Voucher Remark"
                resource="voucherRemark"
                mutationOptions={{
                    onSuccess: (data: any) => {
                        trackEvent("voucher", "add-remark", `voucher ID: ${data.voucherId}`);
                        trackEvent("voucher-remark", "add", `voucher remark ID: ${data.recordId}, voucher ID: ${data.voucherId}`);
                        redirect('show', 'voucher', voucherId)
                    },
                }}
        >
            <CustomBreadcrumbs title="Create Voucher Remark" items={breadcrumbItems} />
            <VoucherRemarkEditForm type="create" />
        </Create>
    )
};

export default VoucherRemarkCreate;