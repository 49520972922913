import React from "react";
import { Tooltip } from "@mui/material";
import CustomIconButton from "../../../../component/common/CustomIconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Swal from "sweetalert2";
import { useQueryClient } from "react-query";
import { unassignProject } from "../../../hook/dataHooks";
import { cleanRelatedCaches, cleanUserProjectRolesCache } from "../utils/projectCacheHandler";

const UserUnassignProjectButton: React.FC<{ user: any, project: any }> = ({ user, project }) => {

    const queryClient = useQueryClient();

    const deleteDialog = () => {
        Swal.fire({
            title: `Un-assign user from project`,
            text: `Are you sure you want to un-assign user ${user.name} from project #${project.recordId}?`,
            icon: 'warning',
            footer: 'The action cannot be reversed!',
            showCancelButton: true,
            focusConfirm: true,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    unassignProject(user.uuid, project.uuid)
                        .then(() => {
                            cleanRelatedCaches(queryClient, user.recordId);
                            cleanUserProjectRolesCache(queryClient, user.uuid, project.uuid);
                            Swal.fire({
                                title: "Unassign user from project Successfully",
                                icon: "success",
                                showCancelButton: false,
                                focusConfirm: true,
                            });
                        })
                        .catch(() => {
                            Swal.fire("Failed to unassign...")
                        })
                }
            })
    };

    return (
        <Tooltip title="Un-assign user from project" disableInteractive>
            <div>
                <CustomIconButton
                    size="small"
                    color="softError"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteDialog();
                    }}>
                    <DeleteOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                </CustomIconButton>
            </div>
        </Tooltip>
    )
};

export default UserUnassignProjectButton;
