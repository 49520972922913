import React from "react";
import {
    Box, Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { useLocaleState } from "ra-core";
import moment from "moment";
import { OctopusPeriodUsageMap } from "../../types/types";

type OctopusConsumptionTableProps = {
    periodUsageMap: OctopusPeriodUsageMap;
}

const OctopusConsumptionTable: React.FC<OctopusConsumptionTableProps> = ({ periodUsageMap }) => {

    const [ locale ] = useLocaleState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    if (Object.keys(periodUsageMap).length === 0) {
        return (<Box>No tariffs found</Box>);
    }

    return (
        <Paper sx={{ m: 1, p: 3, overflow: 'hidden' }}>
            <Typography variant="h4"> Consumption List </Typography>
            <Divider />
            <TableContainer component="div" sx={{ minHeight: 400, maxHeight: 400 }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Period</TableCell>
                            <TableCell>p/kwh</TableCell>
                            <TableCell>Consumption</TableCell>
                            <TableCell>Sub-total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(periodUsageMap).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((day) => {
                            return (
                                <TableRow key={day}>
                                    <TableCell>{moment(periodUsageMap[day].from).locale(locale).format("L LT")}</TableCell>
                                    <TableCell>{periodUsageMap[day].costPerKwh.toFixed(2)}p</TableCell>
                                    <TableCell>{periodUsageMap[day].consumption.toFixed(2)}</TableCell>
                                    <TableCell>{(periodUsageMap[day].cost).toFixed(2)}p</TableCell>
                                </TableRow>
                            )
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={Object.keys(periodUsageMap).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );

};

export default OctopusConsumptionTable;
