import * as React from 'react';
import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useCheckMinimumRequiredProps, RaRecord } from 'ra-core';
import { EditBase } from 'ra-core';
import { EditProps } from "react-admin";
import { CustomEditView } from './CustomEditView';

/**
 * Override ra component
 * last updated @ 4.10.2
 */
export const CustomEdit = <RecordType extends RaRecord = any>(
    props: EditProps<RecordType> & { children: ReactNode }
) => {
    useCheckMinimumRequiredProps('Edit', ['children'], props);
    const {
        resource,
        id,
        mutationMode,
        mutationOptions,
        queryOptions,
        redirect,
        transform,
        disableAuthentication,
        ...rest
    } = props;
    return (
        <EditBase
            resource={resource}
            id={id}
            mutationMode={mutationMode}
            mutationOptions={mutationOptions}
            queryOptions={queryOptions}
            redirect={redirect}
            transform={transform}
            disableAuthentication={disableAuthentication}
        >
            <CustomEditView {...rest} />
        </EditBase>
    );
};

CustomEdit.propTypes = {
    actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    aside: PropTypes.element,
    children: PropTypes.node,
    className: PropTypes.string,
    disableAuthentication: PropTypes.bool,
    hasCreate: PropTypes.bool,
    hasEdit: PropTypes.bool,
    hasShow: PropTypes.bool,
    hasList: PropTypes.bool,
    id: PropTypes.any,
    mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
    mutationOptions: PropTypes.object,
    queryOptions: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    title: PropTypes.node,
    transform: PropTypes.func,
    sx: PropTypes.any,
};
