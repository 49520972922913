import React from "react";
import { Alert, Box, Card, CardContent, Grid, InputAdornment } from "@mui/material";
import {
    DateInput,
    maxValue,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    useEditContext,
    useGetOne
} from "react-admin";
import { useParams } from "react-router-dom";
import Loading from "../../../component/common/Loading";

const VoucherConsumptionEditForm: React.FC<EditFormProps> = ({ type }) => {

    const { voucherId } = useParams();
    const { isLoading, isFetching, data: voucher } = useGetOne<Voucher>("voucher", { id: voucherId ? +voucherId : -1 });
    const { record } = useEditContext();

    if (isLoading || isFetching || ! voucher) {
        return <Loading />;
    }

    const getEditMaxConsumeValue = (): number => {
        if (type === "edit" && record) {
            return +(voucher.amount - voucher.usedAmount + record.amount).toFixed(2);
        }
        return +(voucher.amount - voucher.usedAmount).toFixed(2);
    };

    const CreateAlert = () => {
        return (
            <Alert severity="warning" sx={{ mb: 4 }}>
                Current Voucher Amount: £{voucher.amount.toFixed(2)} <br />
                Current Voucher Used Amount: £{voucher.usedAmount.toFixed(2)} <br />
                You can consume up to £ {getEditMaxConsumeValue().toFixed(2)}
            </Alert>
        );
    };

    const EditAlert = () => {

        const { record } = useEditContext<Voucher>();

        if (! record)
            return null;

        return (
            <Alert severity="warning" sx={{ mb: 4 }}>
                Current Voucher Amount: £{voucher.amount.toFixed(2)} <br />
                Current Voucher Used Amount (Not include before change): £{(voucher.usedAmount - record.amount).toFixed(2)} <br />
                You can consume up to £ {getEditMaxConsumeValue().toFixed(2)}
            </Alert>
        );
    }

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur" toolbar={
            <Box>
                <SaveButton />
            </Box>
        }>
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    {type === "create" ? <CreateAlert /> : <EditAlert />}
                </CardContent>

                <Box sx={{ display: "none" }}>
                    <TextInput name="voucherId" source="voucherId" defaultValue={voucherId} readOnly />
                </Box>


                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <DateInput name="purchaseDate"
                                       source="purchaseDate"
                                       fullWidth
                                       variant="outlined"
                                       required
                                       defaultValue={new Date()}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="amount"
                                         source="amount"
                                         step="0.01"
                                         fullWidth
                                         variant="outlined"
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start">£</InputAdornment>
                                         }}
                                         validate={maxValue(getEditMaxConsumeValue(), `Exceed the voucher amount`)}
                                         required
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    )
}

export default VoucherConsumptionEditForm;
