import React from "react";
import { CustomEdit } from "../../../component/custom/ra/CustomEdit";
import CustomBreadcrumbs from "../../../component/CustomBreadcrumbs";
import { Box } from "@mui/material";
import ProductBarcodeEditForm from "./ProductBarcodeEditForm";
import { ResourceContextProvider } from "react-admin";
import { useParams } from "react-router-dom";

const ProductBarcodeEdit: React.FC = () => {

    const { productId } = useParams();

    return (
        <ResourceContextProvider value={"product/barcode"}>
            <CustomEdit actions={false}
                        component="div"
                        mutationMode="pessimistic"
                        redirect={`/product/${productId}/show`}
            >
                <Box>
                    <CustomBreadcrumbs title="Edit Barcode" items={[]} />
                    <ProductBarcodeEditForm type="edit" />
                </Box>
            </CustomEdit>
        </ResourceContextProvider>
    );
};

export default ProductBarcodeEdit;
