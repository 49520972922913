import React, { Fragment, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import CustomIconButton from "../../../../component/common/CustomIconButton";
import Swal from "sweetalert2";
import { useQueryClient } from "react-query";
import { assignProject } from "../../../hook/dataHooks";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import {
    AutocompleteInput,
    Form,
    RecordContextProvider,
    ReferenceInput,
    ResourceContextProvider,
} from "react-admin";
import { cleanRelatedCaches } from "../utils/projectCacheHandler";

const UserAssignProjectButton: React.FC<{ user: any }> = ({ user }) => {

    const queryClient = useQueryClient();
    const [ open, setOpen ] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmitHandler = (value: any) => {
        if (! value.projectUuid)  return;
        assignProject(user.uuid, value.projectUuid)
            .then(() => {
                handleClose();
                Swal.fire({
                    title: "Assign user to project Successfully",
                    icon: "success",
                    showCancelButton: false,
                    focusConfirm: true,
                }).then(() => {
                    cleanRelatedCaches(queryClient, user.recordId);
                })
            })
            .catch(() => {
                Swal.fire({
                    title: "Failed to assign project...",
                    icon: "error",
                });
            })
    }

    return (
        <Fragment>
            <Tooltip title="Assign user to a project" disableInteractive>
                <div>
                    <CustomIconButton
                        size="small"
                        color="softPrimary"
                        onClick={(e) => {
                            handleClickOpen();
                        }}>
                        <AssignmentReturnIcon />
                    </CustomIconButton>
                </div>
            </Tooltip>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <ResourceContextProvider value="dummy">
                        <RecordContextProvider value={{ projectUuid: undefined }}>
                            <Form onSubmit={onSubmitHandler}>
                                <DialogTitle>Assign User to Project</DialogTitle>
                                <DialogContent>
                                    <ReferenceInput name="projectUuidReferenceInput"
                                                    source="dummy"  // Dummy Don't set the value same as the name AutocompleteInput, otherwise an unless API will be called
                                                    reference="project"
                                                    fullWidth
                                                    filter={{
                                                        unassignedOnly: true,
                                                        userIds: user.recordId,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                    >
                                        <AutocompleteInput name="projectUuid"
                                                           optionText="name"
                                                           optionValue="uuid"
                                                           label="Selected project"
                                                           filterSelectedOptions={false}
                                                           TextFieldProps={{
                                                               InputLabelProps: {
                                                                   shrink: true,
                                                               },
                                                           }}
                                        />
                                    </ReferenceInput>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="warning" onClick={handleClose}>Cancel</Button>
                                    <Button color="primary" type="submit">Submit</Button>
                                </DialogActions>
                            </Form>
                        </RecordContextProvider>
                    </ResourceContextProvider>
                </Dialog>
        </Fragment>
    )
};

export default UserAssignProjectButton;
