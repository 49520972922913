import { Button, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { BRANCH_STATUS, BRANCH_TYPE } from "./BranchConstant";
import { CustomAutocompleteInput } from "../../component/custom/ra/CustomAutocompleteInput";

const BranchEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput name="name"
                                       source="name"
                                       fullWidth
                                       isRequired
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="description" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="address1" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="address2" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextInput source="postcode" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <SelectInput source="status" fullWidth
                                         choices={BRANCH_STATUS} defaultValue="A" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <SelectInput source="type"
                                         fullWidth

                                         choices={BRANCH_TYPE}
                                         defaultValue="P" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <ReferenceInput label="Shop ID" source="shopId" reference="shop" fullWidth>
                                <CustomAutocompleteInput
                                    name="shopId"

                                    optionText={value => value.name}
                                    optionValue="recordId"
                                    TextFieldProps={{
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    );
};

export default BranchEditForm;
