import React from "react";
import { useParams } from "react-router-dom";
import { CustomEdit } from "../../../../component/custom/ra/CustomEdit";
import CustomBreadcrumbs from "../../../../component/CustomBreadcrumbs";
import ProjectRoleEditForm from "./ProjectRoleEditForm";
import { Box } from "@mui/material";
import { useRedirect } from "react-admin";
import { useQueryClient } from "react-query";

const ProjectRoleEdit: React.FC<{}> = ({  }) => {

    const queryClient = useQueryClient();
    const { projectId, roleId } = useParams();
    const redirect = useRedirect();

    return (
        <CustomEdit actions={false}
                    component="div"
                    mutationMode="pessimistic"
                    title="Project Role Management"
                    resource="project/role"
                    id={roleId}
                    queryOptions={{
                        meta: {
                            projectId
                        },
                    }}
                    mutationOptions={{
                        meta: {
                            projectId
                        },
                        onSuccess: () => {
                            queryClient.invalidateQueries({
                                predicate: (query) =>
                                    query.queryKey.length === 3 &&
                                    query.queryKey[0] === "project/role" &&
                                    query.queryKey[1] === "getOne" &&
                                    (query.queryKey[2] as any)?.id === roleId
                            })
                            redirect(`/admin/project/${projectId}/show`);
                        },
                    }}
        >
            <Box>
                <CustomBreadcrumbs title="Project Role Management" items={[]} />
                <ProjectRoleEditForm type="edit" />
            </Box>
        </CustomEdit>
    )
}

export default ProjectRoleEdit;
