import React from "react";
import { Create } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import RetailerEditForm from "./form/RetailerEditForm";

const RetailerCreate: React.FC = () => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Create',
            isCurrent: true,
        },
    ];

    return (
        <Create actions={false}
                component="div"
                title="Retailer Management"
                resource="retailer"
                redirect="show"
        >
            <CustomBreadcrumbs title="Create Retailer" items={breadcrumbItems} />
            <RetailerEditForm type="create" />
        </Create>
    );

};

export default RetailerCreate;
