import React, { Fragment } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Title, useGetList, useRedirect } from "react-admin";
import Grid from "@mui/material/Unstable_Grid2";
import { SUCCESS, WARNING } from "../../../constant/Color";
import ProgressiveImg from "../../../component/ProgressiveImg";

const VoucherIndex: React.FC = () => {

    const redirect = useRedirect();
    const { data, total, isLoading, error, refetch } = useGetList<Retailer>(
        "retailer",
        {
            pagination: { page: 1, perPage: 999 },
            sort: { field: "seq", order: "ASC" },
            filter: {
                isVoucherRetailer: true,
            },
        }
    );

    const redirectToListWithoutFilter = () => {
        redirect(`/voucher/list?filter=${encodeURIComponent(JSON.stringify({}))}`)
    };

    const redirectToListWithFilter = (retailerId: number) => {
        redirect(`/voucher/list?filter=${encodeURIComponent(JSON.stringify({ retailerIds: [ retailerId ] }))}`)
    };

    return (
        <Fragment>
            <Title title="resources.voucher.page.selectByRetailer.title" />
            <Typography variant="h5" sx={{ mx: 2, mt: 2 }}>Select retailer:</Typography>

            <Grid container m={2} spacing={2} sx={{ textAlign: "center" }}>
                <Grid xs={12} md={3}>
                    <Paper
                        component="div"
                        sx={{
                            minHeight: "150px",
                            height: "100%",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: WARNING.normal,
                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: WARNING.hover,
                            },
                        }}
                        onClick={() => redirectToListWithoutFilter()}
                    >
                        <Box sx={{ fontSize: "30px" }}>
                            All
                        </Box>
                    </Paper>
                </Grid>
                {data?.map((retailer) => {
                    return (
                        <Grid xs={12} md={3} key={retailer.name}>
                            <Paper
                                component="div"
                                sx={{
                                    minHeight: "150px",
                                    height: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: SUCCESS.normal,
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: SUCCESS.hover,
                                    },
                                }}
                                onClick={() => redirectToListWithFilter(retailer.recordId)}
                            >
                                <Box>
                                    {retailer.imagePath ?
                                        <ProgressiveImg src={retailer.imagePath} width="150" alt={retailer.name} /> :
                                        <Box>
                                            {retailer.name}
                                        </Box>
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
        </Fragment>
    );
};

export default VoucherIndex;
