import useGetPreviewUrl from "../data/useGetPreviewUrl";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

const DocumentPreviewer = () => {

    const [isPreview, setIsPreview] = useState(false);
    const { data, isLoading, isError, isIdle } = useGetPreviewUrl(isPreview, "eece2a7c-f0a5-463d-bcea-13d50e5eb997/SFO-Page-1-2.pdf");

    useEffect(() => {
        setIsPreview(false);
    }, []);

    if (isLoading)
        return "Loading...";
    if (isError)
        return "Error...";
    if (! isPreview)
        return (
            <Button onClick={() => setIsPreview(true)}>Click to preview</Button>
        )
    if (!data)
        return "Data is empty"

    return (
        <embed
            width="100%"
            height="100%"
            src={data.data.previewUrl}
        />
    );
};

export default DocumentPreviewer;