import React from "react";
import { RichTextField, Show, useShowContext } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Fade, Grid } from "@mui/material";
import GroupedButton from "../../component/GroupedButton";
import { Identifier } from "ra-core";

const RetailerShow: React.FC<{ id?: Identifier }> = ({ id }) => {

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Retailer',
            resource: 'retailer',
            isCurrent: true,
        },
    ];

    const RetailerShowContent: React.FC = () => {

        const { record, isLoading, refetch } = useShowContext<Retailer>();

        if (isLoading)
            return <CircularProgress />;

        if (! record)
            return <span data-testid="retailer-show-record-not-found">Record Not Found</span>;


        return (
            <Fade in={true} timeout={500}>
                <Box sx={{ width: '100%' }}>
                    <Card>
                        <CardHeader
                            title={record.name}
                            action={<GroupedButton />}
                        />
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    Record ID: <span data-testid="retailer-show-field-record-id">{record.recordId}</span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    Name: {record.name}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    Description: <RichTextField name="description" source="description" />
                                </Grid>
                            </Grid>

                            <Divider />

                            <Grid container>
                                <Grid item xs={12}>
                                    <>Created By {record.createdBy} @ {new Date(record.createdDate).toLocaleString()}</>
                                </Grid>
                                <Grid item xs={12}>
                                    <>Last Updated
                                        By {record.updatedBy} @ {new Date(record.updatedDate).toLocaleString()}</>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Fade>
        );
    };

    return (
        <Show actions={false}
              component="div"
              title="Retailer Management"
              resource="retailer"
              id={id}
        >
            <Box component="div">
                <CustomBreadcrumbs title="View Retailer" items={breadcrumbItems} />
                <RetailerShowContent />
            </Box>
        </Show>
    );

};

export default RetailerShow;
