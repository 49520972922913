import { getProjectUuid, getUserUuid } from "../../../auth/AuthUtils";
import { useQuery } from "react-query";
import { useDataProvider } from "react-admin";
import { VoucherDataProvider } from "./voucherDataProvider";

const useVoucherConsumptionDetails = (form: VoucherConsumptionDetailsSearchForm) => {
    const projectUuid = getProjectUuid() || "";
    const userUuid = getUserUuid() || "";
    const dataProvider = useDataProvider<VoucherDataProvider>();

    const {
        isError,
        error,
        data,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(
        [ 'voucherConsumptionDetails', projectUuid, userUuid, form ],
        () => dataProvider.fetchVoucherConsumptionDetails("voucher", {
            projectUuid,
            userUuid,
            form,
        }),
        {
            enabled: ! (projectUuid === "" && userUuid === ""),
            retry: false,
        }
    );

    return { isError, error, data, isLoading, isFetching, refetch };
};

export {
    useVoucherConsumptionDetails,
};
