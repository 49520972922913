import React, { Fragment, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Tab,
    Tabs,
    Theme,
    useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate, useParams } from "react-router-dom";
import DocumentPreviewer from "./component/DocumentPreviewer";
import DownloadButton from "./component/DownloadButton";
import DocumentBreadcrumbs from "./component/DocumentBreadcrumbs";
import DocumentPreviewDetailsTab from "./component/documentPreview/DocumentPreviewDetailsTab";
import DocumentPreviewNotesTab from "./component/documentPreview/DocumentPreviewNotesTab";

export enum PREVIEW_TAB {
    DETAILS,
    NOTES,
    PERMISSIONS,
}

const DocumentPreview = () => {

    const { fileId } = useParams();

    const navigate = useNavigate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const [ tab, setTab ] = useState(PREVIEW_TAB.DETAILS);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Fragment>

            <DocumentBreadcrumbs />

            <Card sx={{ my: 1 }}>
                <CardHeader
                    title="testing.pdf"
                    action={
                        <Stack direction="row">
                            <DownloadButton />
                        </Stack>
                    }
                />
                <Divider />
                <CardContent sx={{ p: 0, pt: "8px", pb: 0, minHeight: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={4} sx={{ borderRight: "1px solid #DDD" }}>
                            <Box sx={{ p: 1, height: "calc(100vh - 250px)", overflowY: "auto" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                                    <Tabs
                                        scrollButtons={true}
                                        value={tab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        sx={{
                                            "& button": {
                                                textTransform: "none",
                                                minWidth: "40px",
                                            },
                                        }}
                                    >
                                        <Tab label="Details" value={PREVIEW_TAB.DETAILS} />
                                        <Tab label="Notes" value={PREVIEW_TAB.NOTES}/>
                                        <Tab label="Permissions" value={PREVIEW_TAB.PERMISSIONS} />
                                    </Tabs>
                                </Box>
                                {tab === PREVIEW_TAB.DETAILS && (<DocumentPreviewDetailsTab />)}
                                {tab === PREVIEW_TAB.NOTES && (<DocumentPreviewNotesTab />)}
                            </Box>
                        </Grid>
                        <Grid xs={12} md={8}>
                            <Box
                                sx={{
                                    p: 1,
                                    height: "calc(100vh - 250px)"
                                }}
                            >
                                <DocumentPreviewer />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Dialog open={false} fullScreen>
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                    <embed
                        width="100%"
                        height="100%"
                        src="https://5hrldw.sharepoint.com/sites/HomeCloudData/_layouts/15/embed.aspx?uniqueId=724e6586-a484-4018-b8b4-3608a9fc1494&access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfZGlzcGxheW5hbWUiOiI2dW9oay5jb20iLCJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvNWhybGR3LnNoYXJlcG9pbnQuY29tQDE0NDFmYjczLTkwNzMtNGViOC05ODY4LTA0ZDRlNzFlNDMxNyIsImNpZCI6IkVnZzVSU0VlY3NxTUYxV3B1WTQ4MHc9PSIsImVuZHBvaW50dXJsIjoicXlXLzVCQXpQM2I1Wm9RMlhXbG1IdFhFWFFoWW05V1ZDNk1WckZPSHpuZz0iLCJlbmRwb2ludHVybExlbmd0aCI6IjExOCIsImV4cCI6IjE3MTQwNTU4ODMiLCJpcGFkZHIiOiI0MC4xMjYuNDEuMTYwIiwiaXNsb29wYmFjayI6IlRydWUiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYW1laWQiOiI5ZThmMWQ2MC1iMGJlLTQ0YWYtYmIxYy04YWUzNWRkYmI2NzFAMTQ0MWZiNzMtOTA3My00ZWI4LTk4NjgtMDRkNGU3MWU0MzE3IiwibmJmIjoiMTcxNDA1NTU4MyIsInJvbGVzIjoiYWxsc2l0ZXMucmVhZCBhbGxmaWxlcy53cml0ZSIsInNpdGVpZCI6IllqZGpOVGhoWlRRdFltSmlNeTAwWVRreUxXRm1OVFl0WmpaaFlqazJNVGcxTXpBdyIsInR0IjoiMSIsInZlciI6Imhhc2hlZHByb29mdG9rZW4ifQ.M-ZIcwiy9VG7KdOyK86DjWrYoOEjee1Kh9oxBHVl79Q" />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DocumentPreview;
