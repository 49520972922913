import React, { Fragment } from "react";
import { Breadcrumbs, Link, Paper } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate, Link as RouterLink } from "react-router-dom";

const DocumentBreadcrumbs: React.FC = () => {

    return (
        <Fragment>
            <Paper sx={{ p:2, my: 1 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link
                        component={RouterLink}
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        to={"/document/explorer"}
                    >
                        <FolderIcon fontSize="small" sx={{ mr: 1 }} />&lt;Root&gt;
                    </Link>
                </Breadcrumbs>
            </Paper>
        </Fragment>
    );
};

export default DocumentBreadcrumbs;
