import React from "react";
import { NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid, StandardTextFieldProps } from "@mui/material";
import { COMMON_STATUS } from "../../constant/CommonStatus";
import { DUE_DATE_TYPE } from "../../constant/DueDateType";
import { CustomAutocompleteInput } from "../../component/custom/ra/CustomAutocompleteInput";

const ProductEditForm: React.FC<{
    type: "create" | "edit";
}> = ({ type }) => {

    const config: StandardTextFieldProps = {
        InputLabelProps: {
            shrink: true,
        }
    };

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>

                        {type === "edit" &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       fullWidth
                                       variant="outlined"
                                       isRequired
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="description"
                                       source="description"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         source="status"
                                         choices={COMMON_STATUS}
                                         fullWidth
                                         variant="outlined"
                                         defaultValue={"A"}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="minStockAmount"
                                         source="minStockAmount"
                                         variant="outlined"
                                         fullWidth
                                         step={1}
                                         defaultValue={0}
                                         isRequired
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="defaultDueDateType"
                                         source="defaultDueDateType"
                                         choices={DUE_DATE_TYPE}
                                         fullWidth
                                         variant="outlined"
                                         isRequired
                                         defaultValue={"X"}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput source="defaultShopId" reference="shop" fullWidth>
                                <CustomAutocompleteInput
                                    name="defaultShopId"
                                    label="Default Shop (Optional)"
                                    optionText={value => value.name}
                                    optionValue="recordId"
                                    variant="outlined"
                                    filterSelectedOptions={false}
                                    emptyText={"N/A"}
                                    TextFieldProps={config}
                                />
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput source="defaultStockLocationId" reference="stock/location" fullWidth>
                                <CustomAutocompleteInput
                                    name="defaultStockLocationId"
                                    label="Default Stock Location (Optional)"
                                    optionText={value => {
                                        return value.name;
                                    }}
                                    optionValue="recordId"
                                    variant="outlined"
                                    filterSelectedOptions={false}
                                    emptyText={"N/A"}
                                    TextFieldProps={config}
                                />
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput source="quantityUnitId" reference="quantityUnit" fullWidth>
                                <CustomAutocompleteInput
                                    name="quantityUnitId"
                                    label="Quantity Unit"
                                    optionText={value => {
                                        if (value.recordId)
                                            return `${value.nameSingular} / ${value.namePlural}`;
                                        else
                                            return value.name;
                                    }}
                                    optionValue="recordId"
                                    variant="outlined"
                                    filterSelectedOptions={false}
                                    isRequired
                                    TextFieldProps={config}
                                />
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput source="productGroupId" reference="productGroup" fullWidth>
                                <CustomAutocompleteInput
                                    name="productGroupId"
                                    label="Product Group"
                                    optionText={value => value.name}
                                    optionValue="recordId"
                                    variant="outlined"
                                    filterSelectedOptions={false}
                                    isRequired
                                    TextFieldProps={config}
                                />
                            </ReferenceInput>
                        </Grid>


                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput source="brandId" reference="brand" fullWidth>
                                <CustomAutocompleteInput
                                    name="brandId"
                                    label="Brand (Optional)"
                                    optionText={value => value.name}
                                    optionValue="recordId"
                                    variant="outlined"
                                    filterSelectedOptions={false}
                                    emptyText={"N/A"}
                                    TextFieldProps={config}
                                />
                            </ReferenceInput>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    )
};

export default ProductEditForm;
