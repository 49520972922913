import { Datagrid, DateField, FunctionField, NumberField, ReferenceField, SelectField, TextField } from "react-admin";
import { Stack } from "@mui/material";
import CustomShowButton from "../../component/CustomShowButton";
import CustomEditButton from "../../component/CustomEditButton";
import CustomCloneButton from "../../component/CustomCloneButton";
import CustomDeleteIconButtonV2 from "../../component/CustomDeleteIconButtonV2";
import VoucherBarcodeDialog from "./VoucherBarcodeDialog";
import VoucherStatus from "./constant/VoucherStatus";
import React from "react";

const VoucherDesktopGrid = () => {

    return (
        <Datagrid bulkActionButtons={false}>
            <FunctionField label="common.table.actions"
                           render={() => (
                               <Stack component="div" spacing={1} direction="row">
                                   <CustomShowButton />
                                   <CustomEditButton />
                                   <CustomCloneButton
                                       omitProps={[
                                           'id', 'recordId', 'createdBy', 'createdDate', 'updatedBy', 'updatedDate',
                                           'effectiveStartDate', 'effectiveEndDate', 'url', 'purchaseDate',
                                           'number', 'pin',
                                           'barcodeText', 'barcodeType',
                                       ]}
                                   />
                                   <CustomDeleteIconButtonV2 IconButtonProps={{
                                       sx: {
                                           "svg": {
                                               fontSize: "16px",
                                           }
                                       }
                                   }} />
                               </Stack>
                           )} />
            <TextField
                source="name"
                label="resources.voucher.common.name"
            />
            <FunctionField label="resources.voucher.common.balance"
                           render={(record: any) => (
                               <>£{(+(record.amount.toFixed(2)) -
                                   +(record.usedAmount.toFixed(2))).toFixed(2)}</>
                           )}
            />
            <FunctionField label="BC"
                           render={(record: Voucher) => (
                               record.barcodeText && record.barcodeType &&
                               <VoucherBarcodeDialog barcodeText={record.barcodeText}
                                                     barcodeType={record.barcodeType} small />
                           )} />
            <NumberField source="amount"
                         label="resources.voucher.common.amount"
                         options={{ style: 'currency', currency: 'GBP' }}
                         textAlign="left"
            />
            <ReferenceField sortable={false} reference="retailer" source="retailerId" link="show">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="usedAmount"
                         label="resources.voucher.common.usedAmount"
                         options={{ style: 'currency', currency: 'GBP' }}
                         textAlign="left"
            />
            <TextField source="issuer"
                       label="resources.voucher.common.issuer"
            />
            <SelectField source="status"
                         label="resources.voucher.common.status"
                         choices={VoucherStatus}
            />
            <DateField source="purchaseDate"
                       label="resources.voucher.common.purchaseDate"
            />
            <TextField source="recordId" label="#" />
        </Datagrid>
    );
};

export default VoucherDesktopGrid;
