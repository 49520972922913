import {
    CREATE,
    DELETE,
    GET_LIST, GET_MANY,
    GET_MANY_REFERENCE,
    GetListParams,
    PaginationPayload,
    SortPayload, UPDATE
} from "react-admin";

const convertHTTPResponse = (response: any, type: string, resource: string, params: any, targetIdField: string = "recordId") => {
    const { headers, json } = response;
    switch (type) {
        case GET_MANY_REFERENCE:
        case GET_LIST:
            if (! json.hasOwnProperty("totalElements")) {
                throw new Error(
                    "The numberOfElements property must be must be present in the Json response"
                );
            }
            return {
                data: json.content.map((resource: any) => ({
                    ...resource,
                    id: resource[targetIdField] ? resource[targetIdField] : resource.id
                })),
                total: parseInt(json.totalElements, 10)
            };
        case CREATE:
            return { data: { ...json, id: json[targetIdField] ? json[targetIdField] : json.id } };
        case GET_MANY:
            return {
                data: json.map((resource: any) => ({
                    ...resource,
                    id: resource[targetIdField] ? resource[targetIdField] : resource.id
                })),
            }
        case DELETE:
            return {
                data: { ...json },
            }
        default:
            return { data: { ...json, id: json[targetIdField] ? json[targetIdField] : json?.id } };
    }
};

const convertToGetListParams = (params: GetListParams) => {
    const { filter, sort, pagination } = params;
    return `${convertToPageAndSize(pagination)}${convertToSort(sort)}${convertToFilter(filter)}`;
}

const convertToPageAndSize = (pagination: PaginationPayload) => {
    if (! pagination)
        return '';

    return `?&page=${pagination.page}&size=${pagination.perPage}`
}

const convertToSort = (sort: SortPayload) => {
    if (! sort)
        return '';

    return `&sort=${sort.field === 'id' ? 'recordId' : sort.field},${sort.order}`;
}

const convertToFilter = (filterObject: any) => {
    if (! filterObject)
        return '';

    return Object.entries(filterObject)
        .reduce((p, [ key, value ]) => {
            // @ts-ignore
            return `${p}&${key}=${encodeURIComponent(value)}`;
        }, '');
};

export { convertHTTPResponse, convertToGetListParams, convertToPageAndSize, convertToSort, convertToFilter };
