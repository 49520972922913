import React from "react";
import { CustomEdit } from "../../component/custom/ra/CustomEdit";
import { Box } from "@mui/material";
import CustomBreadcrumbs from "../../component/CustomBreadcrumbs";
import ProductEditForm from "./ProductEditForm";
import { useParams } from "react-router-dom";

const ProductEdit: React.FC = () => {

    const { productId } = useParams();

    return (
        <CustomEdit actions={false}
                    id={productId}
                    component="div"
                    mutationMode="pessimistic"
                    title="Product Management"
                    resource="product"
                    redirect="show"
        >
            <Box>
                <CustomBreadcrumbs title="Edit Product" items={[]} />
                <ProductEditForm type="edit" />
            </Box>
        </CustomEdit>
    )
}

export default ProductEdit;
