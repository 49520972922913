import OctopusV4ContextProvider, { OctopusV4Context } from "./context/OctopusV4Context";
import { Route, Routes } from "react-router-dom";
import OctopusV4Index from "./OctopusV4Index";
import OctopusV4Tariff from "./OctopusV4Tariff";
import { Fragment, useContext } from "react";
import OctopusV4Property from "./OctopusV4Property";
import OctopusV4TokenUpdate from "./OctopusV4TokenUpdate";
import OctopusV4Consumption from "./OctopusV4Consumption";

const OctopusV4 = () => {

    const Wrapper = () => {

        const { account: { isLoading, data: account }, selectedProperty } = useContext(OctopusV4Context);

        if (isLoading) {
            return <></>;
        }

        if (!account || !selectedProperty) {
            return (<Fragment> Setting not found ! Please move to configuration page before use. </Fragment>);
        }

        return (
            <Routes>
                <Route path="index" element={<OctopusV4Index />} />
                <Route path="property" element={<OctopusV4Property />} />
                <Route path="tariff" element={<OctopusV4Tariff selectedProperty={selectedProperty} account={account} />} />
                <Route path="consumption" element={<OctopusV4Consumption selectedProperty={selectedProperty} account={account} />} />
            </Routes>
        );
    };

    return (
        <OctopusV4ContextProvider>
            <Routes>
                <Route path="*" element={<Wrapper />} />
                <Route path="token/update" element={<OctopusV4TokenUpdate />} />
            </Routes>
        </OctopusV4ContextProvider>
    );
};

export default OctopusV4;
