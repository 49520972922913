import { createTheme } from "@mui/material";
import { defaultTheme, defaultLightTheme, defaultDarkTheme } from "react-admin";
import { SOFT_ERROR_BUTTON } from "./constant/SoftError";

const getAppTheme = (darkMode: boolean) => (
    createTheme({
        ...(darkMode ? defaultDarkTheme : defaultLightTheme),
        palette: {
            ...(darkMode ? defaultDarkTheme.palette : defaultLightTheme.palette),
            background: {
                default: darkMode ? "#222736" : "#fff",
            },
            mainPrimary: {
                light: '#556ee6',
                main: '#485ec4',
                dark: '#4458b8',
                contrastText: '#fff',
            },
            softPrimary: {
                main: '#485ec4',
                light: '#485ec4',
                dark: '#485ec4',
                contrastText: '#fff',
            },
            softError: {
                light: '#556ee6',
                main: '#485ec4',
                dark: '#4458b8',
                contrastText: '#fff',
            },
            xPrimary: {
                main: '#556ee6',
                light: '#556ee6',
                dark: '#556ee6',
                contrastText: '#fff',
            },
            xSecondary: {
                main: '#74788d',
                light: '#74788d',
                dark: '#74788d',
                contrastText: '#fff',
            },
            xSuccess: {
                main: '#34c38f',
                light: '#34c38f',
                dark: '#34c38f',
                contrastText: '#fff',
            },
            xInfo: {
                main: '#50a5f1',
                light: '#50a5f1',
                dark: '#50a5f1',
                contrastText: '#fff',
            },
            xWarning: {
                main: '#f1b44c',
                light: '#f1b44c',
                dark: '#f1b44c',
                contrastText: '#fff',
            },
            xDanger: {
                main: '#f46a6a',
                light: '#f46a6a',
                dark: '#f46a6a',
                contrastText: '#fff',
            },
            xDark: {
                main: '#343a40',
                light: '#556ee6',
                dark: '#a6b0cf',
                contrastText: '#fff',
            },
            xLink: {
                main: '#556ee6',
                light: '#556ee6',
                dark: '#556ee6',
                contrastText: '#fff',
            },
            xLight: {
                main: '#b3b3b3',
                light: '#b3b3b3',
                dark: '#b3b3b3',
                contrastText: '#000',
            },
        },
        components: {
            ...defaultTheme.components,
            MuiTextField: {
                defaultProps: {
                    InputLabelProps: {
                        shrink: true,
                    },
                },
            },
            MuiAutocomplete: {
                defaultProps: {

                },
            },
            MuiButton: {
                variants: [
                    ...SOFT_ERROR_BUTTON,
                    {
                        props: {
                            color: 'softPrimary',
                            variant: "contained",
                        },
                        style: {
                            fontSize: "0.8125rem",
                            fontWeight: "400",
                            color: "#556ee6",
                            backgroundColor: 'rgba(85, 110, 230, 0.1)',
                            "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                                backgroundColor: "#1E69D1",
                                borderRadius: 5,
                            },
                            "&:hover": {
                                backgroundColor: "#4053ad",
                                color: "#fff",
                                opacity: 1,
                            },
                            "&:disabled": {
                                backgroundColor: "#556ee6",
                                color: "#fff",
                                opacity: 0.5,
                            }
                        }
                    },
                    // {
                    //     props: {
                    //         color: 'softError',
                    //     },
                    //     style: {
                    //         fontSize: "0.8125rem",
                    //         fontWeight: "400",
                    //         color: SOFT_ERROR_COLOR,
                    //         backgroundColor: SOFT_ERROR_BACKGROUND_COLOR,
                    //         "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                    //             backgroundColor: "#1E69D1",
                    //             borderRadius: 5,
                    //         },
                    //         "&:hover": {
                    //             backgroundColor: "#f46a6a",
                    //             color: "#fff",
                    //             opacity: 1,
                    //         },
                    //         "&:disabled": {
                    //             backgroundColor: "#f46a6a",
                    //             color: "#fff",
                    //             opacity: 0.65,
                    //             pointerEvents: "none",
                    //         }
                    //     }
                    // },
                ],
            },
            // @ts-ignore
            CustomIconButton: {
                styleOverrides: {
                    root: {
                        color: 'darkslategray',
                    },
                    mainPrimary: {
                        main: "#556ee6",
                        contrastText: "#FFF",
                    },
                },
                variants: [
                    {
                        props: {
                            color: 'mainPrimary',
                        },
                        style: {
                            fontSize: "0.8125rem",
                            fontWeight: "400",
                            color: "#fff",
                            backgroundColor: '#556ee6',
                            "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                                backgroundColor: "#1E69D1",
                                borderRadius: 5,
                            },
                            "&:hover": {
                                backgroundColor: "#4053ad",
                                color: "#fff",
                                opacity: 1,
                            },
                            "&:disabled": {
                                backgroundColor: "#556ee6",
                                color: "#fff",
                                opacity: 0.5,
                            }
                        }
                    },
                    {
                        props: {
                            color: 'softPrimary',
                        },
                        style: {
                            fontSize: "0.8125rem",
                            fontWeight: "400",
                            color: "#556ee6",
                            backgroundColor: 'rgba(85, 110, 230, 0.1)',
                            "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                                backgroundColor: "#1E69D1",
                                borderRadius: 5,
                            },
                            "&:hover": {
                                backgroundColor: "#4053ad",
                                color: "#fff",
                                opacity: 1,
                            },
                            "&:disabled": {
                                backgroundColor: "#556ee6",
                                color: "#fff",
                                opacity: 0.5,
                            }
                        }
                    },
                    {
                        props: {
                            color: 'softError',
                        },
                        style: {
                            fontSize: "0.8125rem",
                            fontWeight: "400",
                            color: "#f46a6a",
                            backgroundColor: 'rgba(244, 106, 106, 0.1)',
                            "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
                                backgroundColor: "#1E69D1",
                                borderRadius: 5,
                            },
                            "&:hover": {
                                backgroundColor: "#f46a6a",
                                color: "#fff",
                                opacity: 1,
                            },
                            "&:disabled": {
                                backgroundColor: "#f46a6a",
                                color: "#fff",
                                opacity: 0.65,
                                pointerEvents: "none",
                            }
                        }
                    },
                ],
            },
            RaAutocompleteInput: {

            },
            RaTextField: {
                defaultProps: {
                    InputLabelProps: {
                        shrink: true,
                    },
                },
            },
            RaDatagrid: {
                styleOverrides: {
                    root: {
                        overflow: "auto",
                        "& .RaDatagrid-tableWrapper": {
                            width: "100%", display: "table", tableLayout: "fixed",
                        },
                        "& .RaDatagrid-headerCell": {
                            fontWeight: 'bold',
                        },
                        // Child 1 = Actions
                        "& .RaDatagrid-row .MuiTableCell-root:nth-of-type(1)": {
                            whiteSpace: 'nowrap', width: '1%',
                        }
                    }
                }
            },
            RaShow: {
                styleOverrides: {
                    root: {
                        "& .RaShow-main": {
                            marginTop: 0,
                        },
                    }
                }
            },
            RaCreate: {
                styleOverrides: {
                    root: {
                        "& .RaCreate-main": {
                            marginTop: 0,
                        },
                    }
                }
            },
            RaEdit: {
                styleOverrides: {
                    root: {
                        "& .RaEdit-main": {
                            marginTop: 0,
                        },
                    }
                }
            },
        }
    })
);

export default getAppTheme;
