import React from "react";
import { useParams } from "react-router-dom";
import { Create, Edit } from "react-admin";
import CustomBreadcrumbs, { BreadcrumbItem } from "../../component/CustomBreadcrumbs";
import RetailerEditForm from "./form/RetailerEditForm";

const RetailerEdit: React.FC = () => {

    const { id } = useParams();

    const breadcrumbItems: BreadcrumbItem[] = [
        {
            label: 'Retailers',
            resource: 'retailer',
            type: 'list',
        },
        {
            label: 'Retailer',
            resource: 'retailer',
            type: 'show',
            id: id,
        },
        {
            label: 'Edit',
            isCurrent: true,
        },
    ];

    return (
        <Edit actions={false}
                component="div"
                title="Retailer Management"
                resource="retailer"
                redirect="show"
        >
            <CustomBreadcrumbs title="Edit Retailer" items={breadcrumbItems} />
            <RetailerEditForm type="edit" />
        </Edit>
    );

};

export default RetailerEdit;
