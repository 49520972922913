import React from "react";
import { BooleanInput, SimpleForm, TextInput } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";

const RetailerEditForm: React.FC<EditFormProps> = ({ type }) => {

    return (
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <Card sx={{ width: "100%" }}>
                <CardContent sx={{ width: "100%" }}>
                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput name="recordId"
                                           source="recordId"
                                           label="Record ID"
                                           fullWidth
                                           variant="outlined"
                                           disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       label="Name"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} md={12}>
                            <TextInput name="description"
                                           source="description"
                                           label="Description"
                                           fullWidth
                                           variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="imagePath"
                                       source="imagePath"
                                       label="Image Path"
                                       fullWidth
                                       variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <BooleanInput name="isVoucherRetailer"
                                          source="isVoucherRetailer"
                                          label="Is voucher retailer?"
                                          fullWidth
                                          variant="outlined"
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </SimpleForm>
    )
};

export default RetailerEditForm;
