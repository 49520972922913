import axios from "axios";
import config from "./../../config.json";
import { getUserUuid } from "../../auth/AuthUtils";
import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import project from "../pages/project";

const assignProject = (userUuid: string, projectUuid: string) => {
    return axios.post(`${config.BASE_URL}/super/admin/user/${getUserUuid()}/project/${projectUuid}/user/${userUuid}/assign`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        }
    });
};

const unassignProject = (userUuid: string, projectUuid: string) => {
    return axios.post(`${config.BASE_URL}/super/admin/user/${getUserUuid()}/project/${projectUuid}/user/${userUuid}/unassign`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        }
    });
};

const getUserProjectAssignedRoles = (userUuid: string, projectUuid: string) => {
    return axios.get<number[]>(`${config.BASE_URL}/super/admin/user/${getUserUuid()}/user/${userUuid}/project/${projectUuid}/role/list`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        }
    })
        .then(res => res.data);
};

const useUserProjectAssignedRoles = (userUuid?: string, projectUuid?: string): UseQueryResult<number[]> => {
    return useQuery([ 'super', 'user/project/roles', projectUuid, userUuid ], () => getUserProjectAssignedRoles(userUuid ?? "", projectUuid ?? ""), {
        enabled: !! userUuid && !! projectUuid
    });
};

const updateUserProjectRoles = (userUuid: string, projectUuid: string, roleIds: number[]) => {
    return axios.post<void>(`${config.BASE_URL}/super/admin/user/${getUserUuid()}/user/${userUuid}/project/${projectUuid}/role/assign`, roleIds, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("auth")}`
        },
    })
        .then(res => res.data);
};

interface UseUpdateUserProjectRolesParams {
    userUuid: string;
    projectUuid: string;
    roleIds: number[];
}

const useUpdateUserProjectRoles = (options: UseMutationOptions<void, unknown, UseUpdateUserProjectRolesParams> = {}): [
    (userUuid: string, projectUuid: string, roleIds: number[]) => void,
    UseMutationResult<void, unknown, UseUpdateUserProjectRolesParams>,
] => {
    const mutation = useMutation<void, unknown, UseUpdateUserProjectRolesParams>(
        ({ userUuid, projectUuid, roleIds }) => {
            return updateUserProjectRoles(userUuid, projectUuid, roleIds)
        }, {
            onMutate: (variables) => {
                if (!!options && !!options.onMutate)
                    options.onMutate(variables);
            },
            onSuccess: (data, variables, context) => {
                if (!!options && !!options.onSuccess)
                    options.onSuccess(data, variables, context);
            },
            onError: (data, variables, context) => {
                if (!!options && !!options.onError)
                    options.onError(data, variables, context);
            },
            onSettled: (data, error, variables, context) => {
                if (!!options && !!options.onSettled)
                    options.onSettled(data, error, variables, context);
            }
        }
    );

    const update = (userUuid: string, projectUuid: string, roleIds: number[]) => {
        return mutation.mutate({ userUuid, projectUuid, roleIds });
    }

    return [ update, mutation ];
}

export {
    assignProject,
    unassignProject,
    getUserProjectAssignedRoles,
    useUserProjectAssignedRoles,
    useUpdateUserProjectRoles
};
