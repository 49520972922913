import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Alert,
    Card,
    CardContent,
    Dialog,
    Grid,
    IconButton,
    InputAdornment,
} from "@mui/material";
import {
    AutocompleteInput,
    DateInput,
    minValue,
    NumberInput,
    ReferenceInput,
    regex,
    required,
    SelectInput,
    TextInput,
    useEditContext, useTranslate,
    Validator,
} from "react-admin";
import VoucherStatus from "./constant/VoucherStatus";
import 'moment/locale/en-gb';
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import NewScanner from "../scanner/NewScanner";
import { convertToFormatName } from "../../helper/BarcodeTypeUtils";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { BARCODE_FORMAT_TYPE } from "../../constant/BarcodeFormatType";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { useParams } from "react-router-dom";

const VoucherEditForm: React.FC<EditFormProps> = ({ type }) => {

    const { record } = useEditContext();
    const translate = useTranslate();

    const inputLabelProps = {
        shrink: true,
    };

    const BarcodeReadingInputGrid: React.FC = () => {

        const { setValue } = useFormContext();

        const [ dialogOpen, setDialogOpen ] = useState(false);
        const codeReader = useRef(new BrowserMultiFormatReader());

        useEffect(() => {
            if (! dialogOpen) {
                codeReader.current.reset();
            }
        }, [ dialogOpen ]);

        const dialogOpenHandler = () => {
            setDialogOpen(true);
        }

        const ScannerDialog = () => {

            const resultHandler = (result: Result) => {
                setValue("barcodeText", result.getText(), { shouldTouch: true, shouldDirty: true, });
                setValue("barcodeType", convertToFormatName(result.getBarcodeFormat()), {
                    shouldTouch: true,
                    shouldDirty: true,
                });
                setDialogOpen(false);
            }

            const dialogCloseHandler = () => {
                setDialogOpen(false);
                codeReader.current.reset();
            }

            return (
                <>
                    {dialogOpen && (
                        <Dialog open={dialogOpen}
                                onClose={dialogCloseHandler}
                        >
                            <NewScanner resultHandler={resultHandler}
                                        codeReader={codeReader}
                            />
                        </Dialog>
                    )}
                </>
            );
        };

        return (
            <Fragment>
                <Grid item xs={12} sm={12} md={4}>
                    <TextInput
                        name="barcodeText"
                        source="barcodeText"
                        label="resources.voucher.common.barcodeText"
                        fullWidth
                        inputProps={{
                            maxLength: 255
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => dialogOpenHandler()}>
                                        <AddAPhotoOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <SelectInput
                        name="barcodeType"
                        source="barcodeType"
                        label="resources.voucher.common.barcodeType"
                        choices={BARCODE_FORMAT_TYPE}
                        fullWidth
                    />
                </Grid>
                <ScannerDialog />
            </Fragment>
        );
    };

    const amountValidator = (): Validator[] => {
        const validators: Validator[] = [];
        if (type === "edit")
            validators.push(minValue(record.usedAmount, "resources.voucher.page.edit.validator.amount.less"));
        validators.push(required("resources.voucher.page.edit.validator.amount.required"));
        return validators;
    }

    return (
        <Fragment>

            {type === "edit" && (
                <Alert severity="warning" sx={{ mb: 4 }}>
                    {translate("resources.voucher.page.edit.message.usedAmountWarning")} <br />
                    {translate("resources.voucher.page.edit.message.currentUsedAmount", {
                        usedAmount: record.usedAmount
                    })}
                </Alert>
            )}

            <Card>
                <CardContent sx={{ width: '100%' }}>

                    <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
                        {type === 'edit' && (
                            <Grid item xs={12} sm={12} md={4}>
                                <TextInput
                                    name="recordId"
                                    source="recordId"
                                    label="resources.voucher.common.recordId"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={inputLabelProps}
                                    disabled
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={4}>
                            <ReferenceInput name="retailerIdInput"
                                            source="retailerId"
                                            reference="retailer"
                                            filter={{
                                                isVoucherRetailer: true
                                            }}
                                            fullWidth
                                            InputLabelProps={inputLabelProps}
                            >
                                <AutocompleteInput name="retailerId"
                                                   optionText="name"
                                                   optionValue="recordId"
                                                   filterSelectedOptions={false}
                                                   TextFieldProps={{
                                                       InputLabelProps: {
                                                           shrink: true,
                                                       },
                                                   }}
                               />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="name"
                                       source="name"
                                       label="resources.voucher.common.name"
                                       fullWidth
                                       variant="outlined"
                                       InputLabelProps={inputLabelProps}
                                       isRequired
                                       validate={required()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <DateInput name="purchaseDate"
                                       source="purchaseDate"
                                       label="resources.voucher.common.purchaseDate"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                                       isRequired
                                       validate={required()}
                                       defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="issuer"
                                       source="issuer"
                                       label="resources.voucher.common.issuer"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                                       isRequired
                                       validate={required()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="amount"
                                         source="amount"
                                         label="resources.voucher.common.amount"
                                         step="0.01"
                                         min={0}
                                         fullWidth
                                         InputLabelProps={inputLabelProps}
                                         variant="outlined"
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start">£</InputAdornment>
                                         }}
                                         validate={amountValidator()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <NumberInput name="actualCost"
                                         source="actualCost"
                                         label="resources.voucher.common.actualCost"
                                         fullWidth
                                         InputProps={{
                                             startAdornment: <InputAdornment position="start">£</InputAdornment>
                                         }}
                                         step="0.01"
                                         variant="outlined"
                                         validate={amountValidator()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="number"
                                       source="number"
                                       label="resources.voucher.common.number"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="pin"
                                       source="pin"
                                       label="resources.voucher.common.pin"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextInput name="url"
                                       source="url"
                                       label="resources.voucher.common.url"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                                       validate={regex(/^http(s|):\/\/.*$/, "URL must be start from http:// or https://")}
                            />
                        </Grid>
                        <BarcodeReadingInputGrid />
                        <Grid item xs={12} sm={12} md={4}>
                            <DateInput name="effectiveStartDate"
                                       source="effectiveStartDate"
                                       label="resources.voucher.common.effectiveStartDate"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <DateInput name="effectiveEndDate"
                                       source="effectiveEndDate"
                                       label="resources.voucher.common.effectiveEndDate"
                                       fullWidth
                                       InputLabelProps={inputLabelProps}
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <SelectInput name="status"
                                         source="status"
                                         label="resources.voucher.common.status"
                                         defaultValue="A"
                                         choices={VoucherStatus}
                                         fullWidth
                                         InputLabelProps={inputLabelProps}
                                         variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default VoucherEditForm;
