import englishMessages from 'ra-language-english';

export const en = {
    ra: {
        ...englishMessages.ra,
        action: {
            ...englishMessages.ra.action,
        },
    },
    common: {
        table: {
            filter: {
                add: "Add Filter",
                reset: "Reset Filter",
            },
            actions: "Actions",
            search: "Search",
        },
        button: {
            show: "Show",
            create: "Create",
            edit: "Edit",
            delete: "Delete",
        },
        status: {
            voucher: {
                pending: "Pending",
                active: "Active",
                partlyConsumed: "Partly Consumed",
                consumed: "Consumed",
                deleted: "Deleted",
            },
            common: {
                active: "Active",
                disabled: "Disabled",
                deleted: "Deleted",
                unknown: "Unknown",
            },
        },
        locale: {
            zh_HK: "Chinese (Traditional)",
            en: "English"
        },
    },
    resources: {
        voucher: {
            menu: "Voucher",
            common: {
                recordId: "Record ID",
                name: "Name",
                purchaseDate: "Purchase Date",
                issuer: "Issuer",
                amount: "Amount",
                usedAmount: "Used",
                actualCost: "Actual Cost",
                number: "Number",
                pin: "PIN",
                url: "URL",
                barcodeText: "Barcode Text",
                barcodeType: "Barcode Type",
                effectiveStartDate: "Effective Start Date",
                effectiveEndDate: "Effective End Date",
                status: "Status",
                balance: "Balance",
            },
            page: {
                index: {
                    title: "Voucher Management - Service Select",
                },
                list: {
                    title: "Voucher Management",
                },
                create: {
                    title: "Create Voucher",
                },
                edit: {
                    title: "Edit Voucher",
                    message: {
                        usedAmountWarning: "New amount is not allowed to be lower than current used amount.",
                        currentUsedAmount: "Current used amount: %{usedAmount}"
                    },
                    validator: {
                        amount: {
                            required: "Amount is required!",
                            less: "Not less than used amount!",
                            more: "Not more than transferable voucher available value",
                        }
                    },
                },
                selectByRetailer: {
                    title: "Retailer Selection",
                },
            },
        },
        energy: {
            octopus: {
                tariff: {
                    v3: {
                        consumptions: "Consumptions",
                        averageConsumptions: "Average Consumptions",
                        charge: {
                            energy: "Energy Charge",
                            standing: "Standing Charge",
                            total: "Total Charge",
                            averageDaily: "Average Daily Charge",
                            averageHourly: "Average Hourly Charge",
                        },
                        totalDays: "Total Days",
                        days: "days",
                        table: {
                            from: "From",
                            to: "To",
                            used: "Used",
                            rate: "Rate",
                            charge: "Charge",
                        },
                    },
                },
            },
        },
    },
};
