import { Resource } from "react-admin";
import UserCreate from "./UserCreate";
import UserList from "./UserList";
import UserShow from "./UserShow";
import { Route } from "react-router-dom";
import UserProjectRoleView from "./UserProjectRoleView";

const User = (
    <Resource key="user"
              name="user"
              create={UserCreate}
              list={UserList}
              show={UserShow}
    >
        <Route path=":id/project/:projectUuid/role" element={<UserProjectRoleView />} />
    </Resource>
);

export default User;
