import React, { Fragment, useEffect } from "react";
import {
    addRefreshAuthToAuthProvider,
    addRefreshAuthToDataProvider,
    Admin,
    combineDataProviders,
    useRedirect
} from "react-admin";
import Project from "./pages/project";
import config from "../config.json";
import { QueryClient } from "react-query";
import { AdminDashboard } from "./AdminDashboard";
import AdminLayout from "./AdminLayout";
import User from "./pages/user";
import { adminProjectRoleDataProvider } from "./hook/AdminProjectRoleDataProvider";
import { sharedAdminDataProvider } from "./hook/sharedAdminDataProvider";
import { sharedSuperAdminDataProvider } from "./hook/sharedSuperAdminDataProvider";
import { refreshAuth } from "../refreshAuth";
import { authProvider } from "../authProvider";
import getAppTheme from "../theme/AppTheme";

const superAdminDataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case "project/role":
            return adminProjectRoleDataProvider(`${config.BASE_URL}/super/admin`);
        case "project":
            return sharedSuperAdminDataProvider(`${config.BASE_URL}/super/admin`, `uuid`);
        case "user":
            return sharedSuperAdminDataProvider(`${config.BASE_URL}/super/admin`);
        default:
            return sharedAdminDataProvider(`${config.BASE_URL}/admin`);
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            retryDelay: 5 * 1000,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    },
});

const FakeLoginPage = () => {
    const redirect = useRedirect();

    useEffect(() => {
        redirect("/login");
    }, [redirect]);

    return <></>;
}

const AdminApp: React.FC = (props: any) => (
    <Admin
        basename="/admin"
        title="Home Cloud Admin"
        // notification={CustomNotification}
        dataProvider={addRefreshAuthToDataProvider(superAdminDataProvider, refreshAuth)}
        authProvider={addRefreshAuthToAuthProvider(authProvider, refreshAuth)}
        layout={AdminLayout}
        loginPage={FakeLoginPage}
        dashboard={AdminDashboard}
        queryClient={queryClient}
        theme={getAppTheme(false)}
        darkTheme={getAppTheme(true)}
        requireAuth
    >
        {permissions => (
            <Fragment>
                {Project}
                {User}
                {/*{ permissions.includes('\'admin\'') && <Resource key="project" name="project" {...adminProject} /> }*/}
            </Fragment>
        )}
    </Admin>
);

export default AdminApp;
